import EventTarget from "@ungap/event-target";

interface EventMap {
    "updated": CustomEvent<InventoryOverview>;
}

export class InventoryStore {
    private _inventoryOverview: InventoryOverview;
    private static _instance = new InventoryStore();
    private eventTarget = new EventTarget();
    private _initializer: Promise<void>;

    private constructor() {
        if ("requestIdleCallback" in window) 
            (<any>window).requestIdleCallback(this.initialize);
        else
            setTimeout(this.initialize);

        // we wait a little b/c we don't want to reload on first hit
        setTimeout(() => {
            window.addEventListener("basket-refresh", this.basketRefreshHandler);
        }, 1000);
        
    }
    static get instance() { return InventoryStore._instance; }

    private emit<K extends Extract<keyof EventMap, string>>(type: K, ev: (type: K) => EventMap[K]) {
        return this.eventTarget.dispatchEvent(ev(type));
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private basketRefreshHandler = async () => {
        const response = await fetch("/api/inventory", {
            method: "get",
            credentials: "same-origin"
        });

        if (!response.ok)
            throw new Error(`Unable to update inventory status: ${response.statusText}`);

        this._inventoryOverview = await response.json();



        this.emit("updated", t => new CustomEvent(t,  { detail: this._inventoryOverview }));
    }

    async getInventory(itemId: number) {
        await this.initialize();
        return "hello";
    }

    async getDiscountEligible(): Promise<number> {
        await this.initialize();

        if (!this._inventoryOverview)
            return 0;

        return this._inventoryOverview.discountEligible;
    }

    async getBasketTotalCount(itemId: number) {
        await this.initialize();
        
        if (!this._inventoryOverview)
            return 0;

        var product = this._inventoryOverview.itemInventory.filter(m => m.itemId == itemId)[0];
        if (!product)
            return 0;

        return product.basket.map(m => m.count).reduce( (a, b) => a + b);
    }

    private initialize = () : Promise<void> => {
        if (this._initializer)
            return this._initializer;

        this._initializer = new Promise(e => {
            this._inventoryOverview = window["inventory"] as InventoryOverview;
            e();
        }); 

        return this._initializer;
    }

}
export type InventoryKind = "local" | "cheap" | "fast" | "best"; 

interface Inventory {
    kind?: InventoryKind;
    count: number;
    vendorId?: number;
}

export interface ProductInventory {
    itemId: number;
    availableStock: Inventory[];
    basket: Inventory[];
}

export interface InventoryOverview {
    discountEligible: number;
    itemInventory: ProductInventory[];
}