import EventTarget from "@ungap/event-target";


interface EventMap {
    "updated": CustomEvent<Basket>;
}

interface OneClickOrderRequest {
    orderedBy: string,
    reference: string
}

export interface OrderResponse {
    orderId: string;
}


export class BasketStore 
{
    private _basket: Basket;
    private static _instance = new BasketStore();
    private eventTarget = new EventTarget();
    private _upsaleQueue: UpsaleLine[] = [];
    private _previousIds: string[] = [];
    private _initializer: Promise<void>;
    // min and max included 
    private static random = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

    private constructor() {

        this.initialize();
        
        // we wait a little b/c we don't want to reload on first hit
        setTimeout(() => {
            window.addEventListener("basket-refresh", this.basketRefreshHandler);
        }, 1000);

        window.addEventListener("basket-upsale", this.basketUpsaleHandler);
        
    }
    static get instance() { return BasketStore._instance; }

    public async current() {
        await this._initializer;
        return this._basket;
    }

    private emit<K extends Extract<keyof EventMap, string>>(type: K, ev: (type: K) => EventMap[K]) {
        return this.eventTarget.dispatchEvent(ev(type));
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private basketUpsaleHandler = (event: CustomEvent<UpsaleLine[]>) => { 
        this._upsaleQueue = event.detail;
    }

    public upsaleItems() : UpsaleLine[] { 
        const items = this._upsaleQueue;
        return items;
    }

    public resetUpsale() {
        this._upsaleQueue = [];
    }

    private basketRefreshHandler = async () => {
        const response = await fetch("/api/shopping-basket", {
            method: "get",
            credentials: "same-origin"
        });

        if (!response.ok)
            throw new Error(`Unable to update inventory status: ${response.statusText}`);

        const newBasket = await response.json() as Basket;

        // find and mark newly added
        const addedIds = this.findAdded(newBasket.items, this._basket.items);
        if (addedIds.length > 0)
            this._previousIds = addedIds;

        this.markRecent(newBasket.items, addedIds);
        this._basket = newBasket;

        this.emit("updated", t => new CustomEvent(t,  { detail: this._basket }));
    }

    public containsMultipleSetsOfItems() {
        return (this._basket.items ?? []).filter(m => m.itemId).length > 1;
    }

    private findAdded(newItems: Item[], oldItems: Item[]) { 
        const newIds = this.flatIds(newItems);
        const oldIds = this.flatIds(oldItems);

        return newIds.filter(m => oldIds.indexOf(m) != -1);
    }

    private markRecent(items: Item[], oldIds: string[]) { 
        if (!items)
            return;
        
        items.forEach(item => { 
            item.isRecent = oldIds.indexOf(item.id) === -1;
            this.markRecent(item.items, oldIds);
        });
    }

    private flatIds(items: Item[]): string[] { 
        if (!items)
            return;
        
        let result = items.map(m => m.id);
        items.forEach(item => {
            result = result.concat(this.flatIds(item.items));
        });
        return result;
    }

    public static formatPrice = (price: number) => {
        let formatted = (Math.round(price) / 100).toLocaleString(navigator.language,  { minimumFractionDigits: 2 });
        if (!document.documentElement.hasAttribute("camouflaged-prices"))
            return formatted;

        const major = formatted
                        .substring(0, formatted.length - 3)
                        .replace(",", "")
                        .replace(".", "");
        
        return `${BasketStore.random(1,9)}.${major}:${BasketStore.random(0,9)}${BasketStore.random(0,9)}`;
    } 

    addItems = async (request: AddRequest): Promise<number> => { 
        const response = await fetch("/api/basket", {
            method: "post",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(request)
        });

        if (response.ok)
            await this.basketRefreshHandler();
        
        return response.status;
    }

    submitOneClickOrder = async (request: OneClickOrderRequest): Promise<OrderResponse> => {
        const response = await fetch("/api/quick-order", {
            method: "post",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(request)
        });

        if (!response.ok)
            throw new Error(`Unable to get submit quick order: ${response.statusText}`);

        return await response.json() as OrderResponse;
    }

    addComment = async (lineId: string, text: string): Promise<void> => {
        const response = await fetch("/api/shopping-basket/comment", {
            method: "post",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                lineId: lineId,
                text: text
            })
        });
    }

    private initialize = () : Promise<void> => {
        if (this._initializer)
            return this._initializer;

        this._initializer = new Promise(e => {
            document.addEventListener("DOMContentLoaded", () => {
                var jsonCarrier = document.querySelector("script[name=current-basket]") as HTMLScriptElement;

                if (!jsonCarrier)
                    return; // fallback

                this._basket = JSON.parse(jsonCarrier.innerText) as Basket;

                console.log("Initialized with basket", this._basket);
                this.emit("updated", t => new CustomEvent(t,  { detail: this._basket }));
                e();
            });
            
        }); 

        return this._initializer;
    }

}

export interface Basket {
    total:        number;
    priceSummary: string;
    items:        Item[];
    itemCount:    number;
    deliveryText: string;
}

export interface Item {
    id: string;
    itemId:      number;
    quantity:    number;
    description: string;
    itemType:    "Generic" | "Rim" | "Tyre" | "Accessory" | "Mounting" | "Related" | "Fee";
    items: Item[];
    price?: number;
    isRecent: boolean;
}

export interface UpsaleLine { 
    quantity: number;
    item: Item;
    parentLine?: string;
}

export interface AddRequest { 
    items: ItemRequest[];
}

export interface ItemRequest { 
    itemId: number;
    quantity: number;
    parentLine?: string;
}