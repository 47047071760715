import { BasketStore, UpsaleLine } from './BasketStore';
import "./BasketUpsaleElement.less";

export class BasketUpsaleElement extends HTMLElement { 
    static tag = "basket-upsale";

    connectedCallback() { 
        this.addEventListener("click", this.clickHandler);
        this.refreshHandler();
    }

    disconnectedCallback() { 
        this.removeEventListener("click", this.clickHandler);
    }

    private clickHandler = async (event: PointerEvent) => {
        if (!(event.target instanceof Element))
            return;
        
        const button = event.target.closest("button");
        if (!button)
            return;
        
        this.toggleAttribute("busy", true);
        await BasketStore.instance.addItems({
            items: [
                {
                    itemId: parseInt(button.getAttribute("item-id")),
                    quantity: parseFloat(button.getAttribute("quantity")),
                    parentLine: button.getAttribute("parent-line")
                }
            ]
        });

        BasketStore.instance.resetUpsale();
        setTimeout(() => {
            this.refreshHandler();
            this.toggleAttribute("busy", false);
        }, 600);

    }

    private refreshHandler = () => { 
        const items = BasketStore.instance.upsaleItems();
        this.toggleAttribute("hidden", items.length === 0); 
        this.innerHTML = this.view(items);
    }

    private view = (upsaleLines: UpsaleLine[]) => `
        <header>
            <div>EW Monteringsservice</div>
            <div>
                Vi afhenter dine gamle hjul, sætter nye dæk på og leverer de nye hjul igen.<br />Klar til at sætte på bilen.
            </div>
        </header>
       
        ${upsaleLines.map(line => this.upsaleView(line)).join("")}
        <div class=tip>
            Tip: Skal hjulene først bruges til næste sæson, så benyt dig af ”ønsket leveringsdato” i varekurven. Det koster ikke ekstra.
        </div>
    `;

    private upsaleView = (upsaleLine: UpsaleLine) => `
        <div>
            <div>${upsaleLine.quantity} x ${upsaleLine.item.description}</div>
            <div class=price>pr. stk. ${BasketStore.formatPrice(upsaleLine.item.price)}</div>
            <button class=no type=button item-id=${upsaleLine.item.itemId} quantity=${upsaleLine.quantity} parent-line=${upsaleLine.parentLine}>
                Tilføj til kurv
            </button>
        </div>
    `;
}

customElements.define(BasketUpsaleElement.tag, BasketUpsaleElement);