import { ElementDataWatcher } from "../Helpers/ElementDataWatcher";
import { StockItem } from './StockItem';
import * as listStyle from "./StockListElement-module.less";
import { MyStockStore, FilterValue } from './MyStockStore';

class StockListElement extends HTMLElement
{
    private dataWatcher: ElementDataWatcher<StockItem[]>;

    connectedCallback() {
        this.className += ` ${listStyle["stock-list"]}`
        this.dataWatcher = new ElementDataWatcher<StockItem[]>(this, "my-stock");
        this.insertAdjacentHTML("beforeend", this.view(this.dataWatcher.data));
        
        MyStockStore.instance.addEventListener("filter-change", this.filterChange);
    }

    disconnectedCallback() {
        MyStockStore.instance.removeEventListener("filter-change", this.filterChange);
    }

    private filterChange = (event: CustomEvent<FilterValue>) => {
        const stockItems = Array.from(this.querySelectorAll("order-control")) as HTMLElement[];

        const filterText = event.detail.text?.toLocaleLowerCase();

        stockItems.forEach(stockItem => {
            if (filterText === "") {
                stockItem.style.display = "block";
            } else {
                if (stockItem.innerText.toLowerCase().indexOf(filterText) == -1)
                    stockItem.style.display = "none";
                else
                    stockItem.style.display = "block";
            }

        });

    }

    private view = (stockItems: StockItem[]) => `
        ${stockItems.map(stockItem => `
            <order-control>
                <script type="application/json+stock-item">${JSON.stringify(stockItem)}</script>
            </order-control>
        
        `).join("")}
    `;
} 


customElements.define("stock-list", StockListElement);