import { PromptDialogElement } from "./PromptDialogElement";
import { TranslationStore } from "../translations/TranslationStore";

// just a conveience method to be able to call from vanilla js
(<any>window).lowStockButAvailableFromRemoteStockPrompt = async (troublesomeItemId, troublesomeItemDesc) => {
    const element = new PromptDialogElement();
    const section = await TranslationStore.instance.section("basket-control");

    element.header = section["confirmDialog.QuantityHigherThanPrimaryStock.Title"];
    element.question = TranslationStore.format(section["confirmDialog.QuantityHigherThanPrimaryStock"], [troublesomeItemId, troublesomeItemDesc]);;
    element.options = [
        {
            id: "no", 
            title: section["confirmDialog.QuantityHigherThanPrimaryStock.No"],
            intent: "secondary"
        },
        {
            id: "yes", 
            title: section["confirmDialog.QuantityHigherThanPrimaryStock.Yes"],
            intent: "primary"
        }
    ];

    return new Promise(resolve => {
        element.addEventListener("selected", (event: CustomEvent) => { resolve(event.detail) }, { once: true });
        document.body.append(element);
    });
}