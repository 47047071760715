import { PromptDialogElement } from "../PromptDialog";
import { Car } from "./CarStore";

class CarWarningActivator {
    private static _instance = new CarWarningActivator();

    public static get instance() { return CarWarningActivator._instance; } 

    constructor() {
        window.addEventListener("car-presented", this.carPresentedHandler)
    }

    private showMessages = (messages: string[]) => {
        if (!messages || messages.length === 0)
            return;

        const dialog = new PromptDialogElement();

        dialog.header = "Bemærk";
        dialog.question = messages.join("<br />");

        dialog.options = [
            { 
                id: "ok",
                title: "OK",
                intent: "primary"
            }
        ];

        document.body.append(dialog);
    }

    private carPresentedHandler = (event: CustomEvent<[Car, string]>) => {
        const car = event.detail[0];
        const registrationNumber = event.detail[1];

        if (!car?.forceStaggered)
            return;

        this.showMessages([
            `Den valgte bilmodel (${registrationNumber ? `${registrationNumber}, ` : ""}BilID: ${car.carId}) skal køre med forskellige for- og bagfælge.`,
            "",
            "Kombinationen af forskellige for- og bagfælge kan ikke bestilles via hjemmesiden, men kun via telefonopkald til Eurowheels.",
            "",
            "Ring for personlig betjening på 70201055 og få et tilbud til den valgte bilmodel."
    ]);
    }
}


var ____ = CarWarningActivator.instance;