import "./SiteHeaderElement-style.less";
import { BasketStore } from '../../features/BasketViewer/BasketStore';

class SiteHeaderElement extends HTMLElement {
    private markPage = () => {
        const path = window.location.pathname;
        if (!path)
            return;

        const link = this.querySelector(`a[href*='${path}']`);
        if (!link)
            return;

        const menuItem = link.closest(".menu-item");
        if (!menuItem)
            return;

        menuItem.setAttribute("active", "");
    }

    private fixSubSubMenus = () => {
        const targets = this.querySelectorAll(".sub-sub-menu[for], .sub-sub-sub-menu[for], .sub-sub-sub-sub-menu[for]");
        for (const target of Array.from(targets)) {
            const link = this.querySelector(`a[id*='${target.getAttribute("for")}']`);
            link.appendChild(target);
        }
    }

    connectedCallback() {
        this.fixSubSubMenus();

        document.addEventListener("pointerdown", (e) => {

            if (e.target instanceof Element) {
                if (e.target.closest("site-header"))
                    return;
            }

            const toBeClosed = Array.from(document.querySelectorAll(".drop-down[open], a[open], .sub-menu[open]"));
            for (const item of toBeClosed) {
                item.removeAttribute("open");
            }
        }, { passive: true });


        const menuItems = Array.from(this.querySelectorAll(".drop-down a"));
        for (const menuItem of menuItems) {

            menuItem.addEventListener("pointerover", (e) => {

                if (e.target !== menuItem)
                    return;

                const toBeClosed = Array.from(document.querySelectorAll("site-header [open]"));

                for (const item of toBeClosed) {

                    if (item.contains(menuItem))
                        continue;


                    item.removeAttribute("open");
                }


                menuItem.toggleAttribute("open", true);
                menuItem.parentElement.toggleAttribute("open", true);
               
                this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));
            }, { passive: true });


            menuItem.addEventListener("touchstart", (e) => {
                console.log("touchstart", menuItem.querySelector("a"));
                var element = e.target as HTMLElement;

                if (e.target !== menuItem)
                    return;

                // if (element instanceof Element) {
                //     if (!element.closest("div").classList.contains("menu-item"))
                //         return;

                //     if (!element.closest("div").querySelector(".sub-menu"))
                //         return;
                // }

                if (element.classList.contains("menu-item") && element.closest("div").querySelector("a"))
                    e.preventDefault();

                if (element.querySelector("a"))
                    e.preventDefault();



                    element.toggleAttribute("open", true);
                    element.querySelector("div")?.toggleAttribute("open", true);

                menuItem.toggleAttribute("open", true);
                menuItem.parentElement.toggleAttribute("open", true);
                this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));

            });

        }

        // var dropdownItems = Array.from(this.querySelectorAll(".drop-down"));
        // dropdownItems.forEach(dropdown => {

        //     dropdown.addEventListener("pointerover", (e) => {
        //         dropdown.setAttribute("open", "");
        //     });

        //     dropdown.addEventListener("touchstart", (e) => {
        //         var element = e.target as HTMLElement;

        //         if (element instanceof Element) {
        //             if (!element.closest("div").classList.contains("menu-item"))
        //                 return;

        //             if (!element.closest("div").querySelector(".sub-menu"))
        //                 return;
        //         }

        //         e.preventDefault();

        //         this.querySelector(".menu-item[hover]")?.removeAttribute("hover");

        //         dropdown.setAttribute("hover", "");
        //         this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));

        //     });

        //     dropdown.addEventListener("mouseover", () => {
        //         this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));
        //     });

        //     //dropdown?.addEventListener("mouseleave", () => dropdown.removeAttribute("hover"));
        // });

        // var submenus = Array.from(this.querySelectorAll(".sub-menu"));
        // submenus.forEach(submenu => {
        //     var close = submenu.querySelector(".close");
        //     close.addEventListener("click", (e) => {
        //         e.preventDefault();
        //         submenu.closest(".menu-item").removeAttribute("hover");
        //     });

        // });

        const contact = this.querySelector("[name=contact]");
        contact?.addEventListener("click", () => {
            window.open("/Pages/CallBack.aspx", "mywindow", "location=no,status=no,scrollbars=no,width=640,height=470");
        });

        const tyreCalc = this.querySelector("[name=tyre-calculator]");
        tyreCalc?.addEventListener("click", () => {
            window.location.href = "/Pages/TyreCalcPage.aspx";
        });

        const impersonation = this.querySelector("[name=impersonation]");
        impersonation?.addEventListener("mouseover", this.showImpersonate);
        impersonation?.addEventListener("touchstart", this.showImpersonate);
        impersonation?.addEventListener("mouseleave", () => {
            impersonation.querySelector("impersonate-selector")?.remove();
        });

        const rims = this.querySelector("[name=rims]");
        rims?.addEventListener("mouseleave", () => tyres.querySelector("quick-search")?.remove());
        rims?.addEventListener("mouseover", e => this.showQuickSearch(e, "rims"));

        const tyres = this.querySelector("[name=tyres]");
        //tyres?.addEventListener("mouseleave", () => tyres.querySelector("quick-search")?.remove());
        tyres?.addEventListener("mouseover", e => this.showQuickSearch(e, "tyres"));

        const basketElement = this.querySelector("[name=basket]");

        basketElement?.addEventListener("mouseover", this.showBasket);
        basketElement?.addEventListener("touchstart", this.showBasket);


        basketElement?.addEventListener("click", () => {
            window.location.href = "/Warehouse/Basket.aspx";
        });

        BasketStore.instance.addEventListener("updated", basket => {
            if (!basket?.detail?.itemCount)
                basketElement.setAttribute("empty", "");
            else
                basketElement.removeAttribute("empty");

            const countElement = basketElement.querySelector(".count") as HTMLDivElement;
            countElement.innerText = basket.detail?.itemCount ? basket.detail.itemCount.toString() : "";

        });

        this.markPage();
    }

    private showBasket = (event: Event) => {
        event.preventDefault();
        const target = (<HTMLElement>event.target).closest("button[name=basket]");
        if (target.hasAttribute("empty"))
            return;

        let basketViewer = target.querySelector("basket-viewer");
        if (!basketViewer) {
            basketViewer = document.createElement("basket-viewer");
            target.appendChild(basketViewer);
        }
    }

    private showImpersonate = (event: Event) => {
        event.preventDefault();
        const target = (<HTMLElement>event.target).closest("button");
        const impersonateSelector = target.querySelector("impersonate-selector");

        if (!impersonateSelector) {
            target.appendChild(document.createElement("impersonate-selector"));
        }
    }

    private showQuickSearch = (event: Event, type: "tyres" | "rims") => {
        const target = (<HTMLElement>event.target).closest("a").parentElement;

        if (!target)
            return;

        let quickSearch = target.querySelector("quick-search");

        if (!quickSearch) {
            quickSearch = document.createElement("quick-search");
            quickSearch.setAttribute("type", type);
            target.appendChild(quickSearch);
        }
    }
}

customElements.define("site-header", SiteHeaderElement);



document.addEventListener("DOMContentLoaded", () => {
    const links = document.querySelectorAll("#main_menu .sub-sub-menu a");
    for (const link of Array.from(links)) {

        if (link.innerHTML.trim() === "")
            link.remove();
    }


});