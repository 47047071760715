import "./AccessoryStockElement.less";

enum StockLevel {
    plenty = 40, // green
    remote = 30, // blue
    few = 20, // yellow
    coming = 10, // orang
    none = 0 // red
}

export class AccessoryStockElement extends HTMLElement {
    static tag = "accessory-stock";
    private localStock = parseInt(this.getAttribute("local-stock") ?? "0");
    private remoteStock = parseInt(this.getAttribute("remote-stock") ?? "0");
    private quantityInPurchase = parseInt(this.getAttribute("quantity-in-purchase") ?? "0");
    private stockLevel = parseInt(this.getAttribute("stock-level") ?? "0") as StockLevel;

    connectedCallback() {
        this.innerHTML = this.view();
    }

    private view = () => `
        <img  src='//r2.eurowheels.dk/img/stockrect${this.stockLevel}.png' />
        ${this.stockText()}
    `;

    private stockText = () => {
        switch (this.stockLevel) {
            case StockLevel.plenty: // 40
                return "> 20";
            case StockLevel.remote: // 30
                return this.remoteStock >= 20 ? "> 20" : this.remoteStock;
            case StockLevel.few: // 20
                return this.localStock >= 20 ? "> 20" : this.localStock;
            case StockLevel.coming: // 10
                return this.quantityInPurchase >= 20 ? "> 20" : this.quantityInPurchase;
            case StockLevel.none: // 0
                return "0";
        }
    }
}

customElements.define(AccessoryStockElement.tag, AccessoryStockElement);