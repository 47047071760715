import "./UserCommentEditorElement.less";
import { BasketStore } from '../BasketViewer/BasketStore';

export class UserCommentEditorElement extends HTMLElement {
    private _saveButton: HTMLButtonElement;
    private _textInput: HTMLInputElement;
    private _cancelButton: HTMLButtonElement;
    private _closeHandler = () => this.remove();
    static tag = "user-comment-editor";

    lineId: string;
    text: string;

    connectedCallback() {
        this.innerHTML = this.view();

        this._saveButton = this.querySelector("[name=save]");
        this._saveButton.addEventListener("click", this.saveComment);
        
        this._cancelButton = this.querySelector("[name=cancel]");
        this._cancelButton.addEventListener("click", this._closeHandler);


        this._textInput = this.querySelector("input");
        this.querySelector("input").focus();

        this.addEventListener("keydown", this.keyboardHandler);
    }

    private keyboardHandler = (event: KeyboardEvent) => {

        switch(event.key) {
            case "Escape": 
                event.preventDefault();
                this._closeHandler();
                break;

            case "Enter":
                event.preventDefault();
                this.saveComment();
        }
    }

    disconnectedCallback() {
        this._saveButton.removeEventListener("click", this.saveComment);
        this._cancelButton.removeEventListener("click", this._closeHandler);
        this.removeEventListener("keydown", this.keyboardHandler);
    }

    private saveComment = async () => {
        this.toggleAttribute("busy", true);
        await BasketStore.instance.addComment(this.lineId, this._textInput.value);
        this.dispatchEvent(new CustomEvent("comment-saved", { bubbles: true, detail: this._textInput.value }));
        this.remove();
    }

    private view = () => `        
        <input type=text maxlength=50 placeholder="Skriv en kommentar ..." value="${this.text || ""}">
        <div name=buttons>
            <button class="none" name=cancel type=button>Annuller</button>
            <button class="none" name=save type=button>Gem</button>
        </div>`;
}

customElements.define(UserCommentEditorElement.tag, UserCommentEditorElement);