import "./BuyButtonElement.less";

import { BasketStore } from "../BasketViewer/BasketStore"; 
import { TyreAddedElement } from '../Tyres/TyreAddedElement';

class BuyButtonElement extends HTMLElement { 
    static tag = "buy-button";
    private button: HTMLButtonElement;

    connectedCallback() { 
        this.button = document.createElement("button");
        this.button.setAttribute("type", "button");
        this.button.innerText = "Køb";
        this.button.addEventListener("click", this.clickHandler);
        this.append(this.button);
    }

    private clickHandler = async () => { 
        if (this.button.disabled)
            return;
        
        this.button.disabled = true;
        
        try {
            const element = new TyreAddedElement();

            const top = document.documentElement.scrollTop;
            element.style.top = `${top}px`;

            document.body.append(element);
                
            const status = await BasketStore.instance.addItems({
                items: [
                    {
                        itemId: parseInt(this.getAttribute("item-id")),
                        quantity: parseInt(this.getAttribute("quantity"))
                    }
                ]
            });


            if (status == 401)
                alert("Det er nødvendigt at være logget ind.");

        } finally { 
            this.button.disabled = false;
        }
    }
}

customElements.define(BuyButtonElement.tag, BuyButtonElement);