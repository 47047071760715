import "./ProductControlElement.less";
import { StockIndicatorElement } from './StockIndicatorElement';

export class ProductControlElement extends HTMLElement { 
    static tag = "product-control";
    private _quantityDropdown: HTMLElement;

    connectedCallback() { 
        this.innerHTML = this.view();
        this._quantityDropdown = this.querySelector("quantity-dropdown");
        this.addEventListener("change", this.quantityChange);
    }

    disconnectedCallback() { 
        this.removeEventListener("change", this.quantityChange);
    }

    private quantityChange = () => { 
        this.querySelector("buy-button").setAttribute("quantity", this._quantityDropdown.getAttribute("value"));
    }

    view = () => `
        <div class=image><img src="${this.getAttribute("image")}" /></div>
        <div class=item-id>${this.getAttribute("item-id")}</div>
        <div class=item-no>${this.getAttribute("item-no")}</div>
        <div class=description>${this.getAttribute("description")}</div>
        <div class=local-stock>
            <${StockIndicatorElement.tag} local-stock=${this.getAttribute("local-stock")} stock-level=${this.getAttribute("stock-level")}></${StockIndicatorElement.tag}>
        </div>
        <div class=price>${this.getAttribute("price")}</div>
        <div class=buy>
            <quantity-dropdown value=1 max-value=${this.getAttribute("local-stock")} inventory-kind=local></quantity-dropdown>
            <buy-button item-id=${this.getAttribute("item-id")} quantity=${this.getAttribute("local-stock")}></buy-button>
        </div>
    `;
}

customElements.define(ProductControlElement.tag, ProductControlElement);
