import "./OrderNotificationElement.less";

class OrderNotificationElement extends HTMLElement {
    private _enablePhoneNotification: HTMLInputElement;
    private _notificationPhoneNumber: HTMLInputElement;
    private _enableEmailNotification: HTMLInputElement;
    private _notificationEmailAddress: HTMLInputElement;
    private _nightDelivery: HTMLInputElement;
    private _canBePlaced: HTMLInputElement;

    private _expand: HTMLButtonElement;
    private _hide: HTMLButtonElement;
    private _expandHandler = () => requestAnimationFrame(() => this.toggleAttribute("open"));
    private _summary: HTMLDivElement;

    connectedCallback() { 

        this.innerHTML = this.view();
        this._summary = this.querySelector("[name=summary]");
        this._expand = this.querySelector("[name=expand]");
        this._expand.addEventListener("click", this._expandHandler);
        this._hide = this.querySelector("[name=hide]");
        this._hide.addEventListener("click", this._expandHandler);
        
        this._enablePhoneNotification = document.querySelector("[name=sms-notification-enabled]");
        this._notificationPhoneNumber = document.querySelector("[name=sms-notification]");
        this._enableEmailNotification = document.querySelector("[name=email-notification-enabled]");
        this._notificationEmailAddress = document.querySelector("[name=email-notification]");
        this._nightDelivery = document.querySelector("[name=night-delivery]");
        this._canBePlaced = document.querySelector("[name=can-be-placed]");


        this._enablePhoneNotification.addEventListener("change", this.refresh);
        this._enableEmailNotification.addEventListener("change", this.refresh);
        this._nightDelivery.addEventListener("change", this.refresh);
        this._canBePlaced.addEventListener("change", this.refresh);
        this.refresh();
    }

    disconnectedCallback() {
        this._enablePhoneNotification.removeEventListener("change", this.refresh);
        this._enableEmailNotification.removeEventListener("change", this.refresh);
        this._nightDelivery.removeEventListener("change", this.refresh);
        this._canBePlaced.removeEventListener("change", this.refresh);
        this._expand.removeEventListener("click", this._expandHandler);
        this._hide.removeEventListener("click", this._expandHandler);
    }

    private summaryView = () => { 
        const indicators: string[] = [];

        if (this.hasAttribute("email-notification-enabled"))
            indicators.push(`${require("!!raw-loader!../../images/check.svg").default}Email`);

        if (this.hasAttribute("sms-notification-enabled"))
            indicators.push(`${require("!!raw-loader!../../images/check.svg").default}SMS`);
        
        if (this.hasAttribute("can-be-placed"))
            indicators.push(`${require("!!raw-loader!../../images/check.svg").default}Må stilles`);
        
        if (this.hasAttribute("night-delivery"))
            indicators.push(`${require("!!raw-loader!../../images/check.svg").default}Nat mulig`);
        
        if (indicators.length === 0)
            return "(ingen)";
            
        
        return indicators.join("&nbsp;&nbsp;&nbsp;");
    };

    private view = () => `
        <div name=overview>
            <h3>Levering</h3>
            <div name=summary>${this.summaryView()}</div>
            <button class=nope name=expand type=button>${require("!!raw-loader!../../images/expand.svg").default}Udvid</button>
            <button class=nope name=hide type=button>${require("!!raw-loader!../../images/hide.svg").default}Skjul</button>
        </div>
        <div name=note>Bemærk: Ikke alle speditører tilbyder dette</div>
        <div name=options>
            <fieldset>
                <label>
                    <input type=checkbox name=email-notification-enabled ${this.hasAttribute("email-notification-enabled") ? "checked" : ""} />Email
                </label>
                <input type=text name=email-notification value="${this.getAttribute("email-notification") || ""}" />
            </fieldset>
            
            <fieldset>
                <label>
                    <input type=checkbox name=sms-notification-enabled ${this.hasAttribute("email-notification-enabled") ? "checked" : ""} />Sms
                </label>
                <input type=text name=sms-notification value="${this.getAttribute("sms-notification") || ""}" />
            </fieldset>    
            <fieldset>
                <label>
                    <input type=checkbox name=can-be-placed ${this.hasAttribute("can-be-placed") ? "checked" : ""} />Må stilles
                </label>
                <label>
                    <input type=checkbox name=night-delivery ${this.hasAttribute("night-delivery") ? "checked" : ""} />Nat mulig
                </label>
                <label style="flex:1">
                    <input type=checkbox name=drop-shipment ${this.hasAttribute("drop-shipment") ? "checked" : ""} />${this.getAttribute("drop-shipment-text")}
                </label>
            </fieldset>  
        </div>`;
      
    private refresh = () => {
        this.toggleAttribute("email-notification-enabled", this._enableEmailNotification.checked);
        this.toggleAttribute("sms-notification-enabled", this._enablePhoneNotification.checked);
        this.toggleAttribute("can-be-placed", this._canBePlaced.checked);
        this.toggleAttribute("night-delivery", this._nightDelivery.checked);
        
        this._notificationPhoneNumber.disabled = !this.hasAttribute("sms-notification-enabled");
        this._notificationEmailAddress.disabled = !this.hasAttribute("email-notification-enabled");
        this._summary.innerHTML = this.summaryView();
    }
}

customElements.define("order-notification", OrderNotificationElement);