import "./QuickOrderElement-style";
import { BasketStore, Basket, OrderResponse } from './BasketStore';
import { PromptDialogElement } from "../PromptDialog";
import { WaitSpinnerElement } from "../Spinner";
import { Task } from "../System";

export class QuickOrderElement extends HTMLElement {
    private buttonTitle = () => this.getAttribute("button-title") ?? "Bestil ordre nu!";
    static tag = "quick-order";
    private _oneclickButton: HTMLButtonElement;

    connectedCallback() {
        if (this.hasAttribute("disabled")) {
            this.remove();
            return;
        }

        this.innerHTML = this.view();
        this._oneclickButton = this.querySelector("button");
        this._oneclickButton.addEventListener("click", this.order);
        BasketStore.instance.addEventListener("updated", this.baseketUpdateHandler);
    }

    disconnectedCallback() {
        this._oneclickButton?.removeEventListener("click", this.order);
        BasketStore.instance.removeEventListener("updated", this.baseketUpdateHandler);
    }

    private baseketUpdateHandler = (event: CustomEvent<Basket>) => {
        const messageElement = this.querySelector("[name=delivery-message]") as HTMLElement;

        if (!messageElement)
            return;

        messageElement.innerText = event.detail.deliveryText;

        if (BasketStore.instance.containsMultipleSetsOfItems()) {
            this._oneclickButton.disabled = true;
            this._oneclickButton.setAttribute("title", "1 klik ikke tilgængeligt, da i forvejen indeholder varer.")
        } else {
            this._oneclickButton.disabled = false;
            this._oneclickButton.setAttribute("title", "")
        }
    }

    private referenceRequired() {
        if (document.documentElement.hasAttribute("order-reference-required"))
            return true;

        return this.hasAttribute("reference-required");
    }

    private order = async () => {
        if (this._oneclickButton.disabled)
            return;

        const orderedBy = this.querySelector("[name=ordered-by]") as HTMLInputElement;
        const reference = this.querySelector("[name=reference]") as HTMLInputElement;

        // we do this runtime since control is rendered hidden and required fields makes chrome/telerik angry, once changed this can be cleaned up and simplified
        reference.toggleAttribute("required", this.referenceRequired());

        const status = reference.checkValidity();

        reference.toggleAttribute("required", false);


        if (!status) {
            reference.toggleAttribute("error", true);
            reference.focus();
            return;
        }


        if (BasketStore.instance.containsMultipleSetsOfItems()) {
            const prompt = new PromptDialogElement();
            prompt.header = "Kurven indeholder flere sæt af varer";
            prompt.question = `
                <p>Obs. Der ligger flere varer i kurven end det du lige har søgt på og bestilt.</p>
                <p>Vil du stadig fortsætte med din 1-klik bestilling?</p>
            `;
            prompt.options = [{ id: "no", intent: "secondary", title: "Nej, gå til kurv" }, { id: "yes", intent: "primary", title: "Ja, bestil ordre" }];

            const response = await prompt.show();

            if (response === "yes")
                this.doOrder(orderedBy.value, reference.value);
            else
                this.goToBasket();
        } else {
            this.doOrder(orderedBy.value, reference.value);
        }
    }

    private goToBasket = () => {
        window.location.href = `/Warehouse/Basket.aspx`;
    }

    private doOrder = async (orderedBy, reference) => {

        const element = WaitSpinnerElement.show();
        let orderResponse: OrderResponse;

        try {
            orderResponse = await BasketStore.instance.submitOneClickOrder({
                orderedBy: orderedBy,
                reference: reference
            });
        } catch(error) {
            element[Symbol.dispose]();
        }

        window.location.href = `/Warehouse/ThankYou.aspx?DocID=${orderResponse?.orderId}`;
    }


    private view = () => `
    <header>
        <div>Afslut nemt med 1-klik-bestilling</div>
        <div name=delivery-message></div>
    </header>
    <div>
        <label>Bestilt af
            <input type=text name=ordered-by />
        </label>
        <label>Reference ${this.referenceRequired() ? "*" : ""}
            <order-requisition-validator target="input[name=reference]"></order-requisition-validator>
            <input type=text name=reference />
        </label>
        <button type=button class="btn-green">${this.buttonTitle()}</button>
    </div>`;

}

customElements.define(QuickOrderElement.tag, QuickOrderElement);