class DeliveryAddressActivator {
    private static _instance = new DeliveryAddressActivator();

    private constructor() {
        const addressElement = document.querySelector("[name=delivery-address]");
        if (addressElement) { 
            addressElement.addEventListener("input", () => { 
                const button = addressElement.querySelector("button");
                
                if (button)
                    button.disabled = false;
            });
        }
    }
}


