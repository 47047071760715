import { PromptDialogElement, PromptOption } from "./PromptDialogElement";
import "./legacyDialogs";


import "./PromitDialogActivator";

(<any>window).legacyPrompt = (header: string, question: string, options: PromptOption[]) => {
    const element = new PromptDialogElement();

    element.header = header;
    element.question = question;
    element.options = options;

    return new Promise(resolve => {
        element.addEventListener("selected", resolve, { once: true });
        document.body.append(element);
    });


}

export { PromptDialogElement, PromptOption };