import { ProductDisplayElement } from "./ProductDisplayElement";
import { Product, ProductList, ProductsStore } from "./ProductsStore";
import "./ProductCollectionElement.less";

export class ProductCollectionElement extends HTMLElement {
    static tag = "product-collection";

    connectedCallback() {
        var productList = ProductsStore.instance.getProducts();

        if (!productList)
            return;

        this.refresh(productList);

        ProductsStore.instance.addEventListener("products-updated", this.onProductsUpdated);

        this.addEventListener("click", this.onClick);
    }

    private onClick = (event: MouseEvent) => {   
        if (!(event.target instanceof HTMLElement))
            return;

        if (!event.target.closest("header"))
            return;

        event.target.closest("section")?.toggleAttribute("collapsed");
    }

    private onProductsUpdated = (event: CustomEvent<ProductList>) => {
        this.refresh(event.detail);
    }

    private refresh = (productList: ProductList) => {
        const productTypes = ProductsStore.instance.getProductTypes();


        const result: { [key: number]: Product[] } = {};
        // this ensures sorting
        for (const product of productList.products) {
            const key = Object.keys(productTypes).find(key => productTypes[key] === product.category);

            if (!result[key])
                result[key] = [];

            result[key].push(product);
        }

        this.innerHTML = this.mainView(result, productTypes);
    }

    private mainView = (products: { [key: number]: Product[] }, productTypes: { [key: number]: string }) => `
        ${Object.keys(products).map(key => `
        
            <section>
                <header>${productTypes[key]} (${productTypes[key].length})</header>
                <div>${this.view(products[key])}</div>
            </section>
        
        `).join("")}
    `

    private view = (products: Product[]) => `
        ${products.map(p => `
        <${ProductDisplayElement.tag}  
            item-id="${p.itemId}"
            item-no = "${p.itemNo}"
            text-1="${p.text1}"
            text-2="${p.text2}"
            image="${p.largeImage}"
            local-stock=${p.localStock ?? 0}
            remote-stock=${p.remoteStock ?? 0}
            quantity-in-purchase=${p.quantityInPurchase ?? 0}
            stock-level=${p.stockLevel ?? 0}
            gross-price=${p.grossPrice}
            ${p.netPrice ? `net-price=${p.netPrice}` : ""}
            categroy="${p.category}"
        >
        </${ProductDisplayElement.tag}>`).join("")}
    `  
}

customElements.define(ProductCollectionElement.tag, ProductCollectionElement);