import "./QuickSearchElement-style";
import * as headerElements from "../SiteHeader/HeaderElements-module.less";

class QuickSearchElement extends HTMLElement {
    private static readonly Licenseplate = /([a-zA-Z]{2}\d{5})/;
    private input: HTMLInputElement

    connectedCallback() {
        this.className += ` ${headerElements["popup-box"]}`;
        this.innerHTML = this.view();

        this.input = this.querySelector("input");

        this.input.addEventListener("keyup", this.keyHandler);

        document.addEventListener("click", this.globalClickHandler);

        this.input.focus();

        this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));

        document.addEventListener("popup", this.closeHandler);
        document.addEventListener("click", this.globalClickHandler);
    }

    disconnectedCallback() {
        document.removeEventListener("popup", this.closeHandler);
        document.removeEventListener("click", this.globalClickHandler);
    }

    private closeHandler = () => this.remove();

    private keyHandler = (event: KeyboardEvent) => {
        switch(event.key) {
            case "Escape": 
                this.remove();
                break;

            case "Enter":
                this.go(); 
                break;
        }
    };

    private go() {
        const value = this.input.value;

        if (value?.length < 2)
            return;

        switch(this.getAttribute("type")) {
            case "rims":
                if (QuickSearchElement.Licenseplate.test(value))    
                    window.location.href = `/warehouse/selectrims.aspx?m=registration&registration=${value}`;
                else    
                    window.location.href = `/warehouse/selectrims.aspx?m=rim&RimSize=&RimWidth=&Offset=&Price=&DesignID=&PcdQty=&PcdDel=&SearchText=${value}&DeliveryBy=2`;
                break;
            case
                "tyres":
                if (QuickSearchElement.Licenseplate.test(value))    
                    window.location.href = `/warehouse/selecttyres.aspx?m=registration&registration=${value}`;
                else    
                    window.location.href = `/warehouse/selecttyres.aspx?m=tyre&TyreWidth=&TyreHeight=&RimSize=&TyreSeason=&Price=&Manufacturer=${value}&VehicleType=&DeliveryBy=2`;
                break;

            default:
                throw new Error(`Unknown type: ${this.getAttribute("type")}`);
        }

    }

    private globalClickHandler = async (event) => {
        if (!(event.target instanceof Element))
            return;

        if (this.contains(event.target))
            return;

        this.remove();
    }

    private view = () => `
        <div>
            <label for=debtor>${this.getAttribute("type") == "tyres" ? "Dæk fritekst" : "Fælg fritekst"}</label>  
            <input type=text id=debtor placeholder="" />
        </div>
    `;
}

customElements.define("quick-search", QuickSearchElement);