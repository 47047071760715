import "./ShippingCarrierElement.less";

export class ShippingAgentElement extends HTMLElement { 
    static tag = "shipping-carrier";
    private _expandHandler = () => requestAnimationFrame(() => this.toggleAttribute("open")); 
    private _summary: HTMLDivElement;
    private _carriers: ShippingCarrier[];

    private _overview: HTMLElement;

    connectedCallback() { 
        this._carriers = JSON.parse(this.querySelector("script").innerText) as ShippingCarrier[]; 

        console.log("carr", this._carriers);

        this.insertAdjacentHTML("beforeend", this.view(this._carriers));

        this._summary = this.querySelector("[name=summary]");
        this._overview = this.querySelector("[name=overview]");

        
        this._overview.addEventListener("click", this._expandHandler);
        this.addEventListener("input", this.inputHandler); 
 
        this.refresh();
    }

    disconnectedCallback() {  
        this._overview.removeEventListener("click", this._expandHandler); 
        this.removeEventListener("input", this.inputHandler); 
    }

    private refreshSummary = () => { 
        const selected = this._carriers.filter(c => c.id === this.getAttribute("value"))[0];

        if (!selected)
            return;
        
        this._summary.setAttribute("title", selected.description.replace("</ br>", "\n"));
        return `${require("!!raw-loader!../../images/check.svg").default} ${selected.name}`;
    }
        
    private inputHandler = () => { 
        this.setAttribute("value", (this.querySelector("input:checked") as HTMLInputElement).value);

        // add event instead?
        (document.querySelector("#loading") as HTMLElement).style.display = "";
        (document.querySelector(".btnSaveSaleDoc") as HTMLElement).click();

        //this.refresh();
    }

    refresh = () => { 
        for (const label of Array.from(this.querySelectorAll("label"))) {
            label.toggleAttribute("checked", !!label.querySelector("input:checked"));
        }

        this._summary.innerHTML = this.refreshSummary();
    }

    private view = (carriers: ShippingCarrier[]) => `
        <div name=overview>
            <h3>${this.getAttribute("caption")}</h3>
            <div name=summary></div>
            <button class=nope name=expand type=button>${require("!!raw-loader!../../images/expand.svg").default}Udvid</button>
            <button class=nope name=hide type=button>${require("!!raw-loader!../../images/hide.svg").default}Skjul</button>
        </div>
        <div name=options>
            ${carriers.map(carrier => `
                <label>
                    <input type="radio" name="carrier"${carrier.id === this.getAttribute("value") ? " checked" : ""} value="${carrier.id}" /> 
                    <div>
                        <div>${carrier.name}</div>
                        <div>${carrier.description}</div>
                    </div>
                </label>                
            `).join("")}
        </div>`;
}

interface ShippingCarrier { 
    id: string;
    name: string;
    description: string;
}

customElements.define(ShippingAgentElement.tag, ShippingAgentElement);

