import { ProductControlElement } from "./ProductControlElement";
import "./ProductListElement.less";

class ProductListElement extends HTMLElement { 
    static tag = "product-list";

    connectedCallback() { 
        const products = JSON.parse(this.querySelector("script").innerText) as Product[];
        console.log("products", products);

        this.innerHTML = this.view(products);
    }

    view = (products: Product[]) => `
        ${products.map(product => this.productView(product)).join("")}
    `;

    productView = (product: Product) => `
        <${ProductControlElement.tag} 
            item-id="${product.itemId}" 
            description="${product.description}"
            image="${product.image}"
            local-stock=${product.localStock}
            stock-level=${product.stockLevel}
            price=${product.price}
            item-no=${product.itemNo}></${ProductControlElement.tag}>
    `;
}

customElements.define(ProductListElement.tag, ProductListElement);


interface Product { 
    itemId: number;
    description: string;
    image: string;
    localStock: number;
    price: number;
    itemNo: string;
    stockLevel: number;
}