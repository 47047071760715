import "./ZoomableImageElement-style";

export class ZoomableImageElement extends HTMLElement {
    static tag = "zoomable-image";
    connectedCallback() {
        const img = document.createElement("img");
        img.setAttribute("draggable", "false");
        img.setAttribute("loading", this.getAttribute("loading") || "lazy");
        img.src = this.getAttribute("source");
        this.append(img);

        if (this.hasAttribute("allow-zoom"))
            this.addEventListener("click", this.zoomHandler);
    }

    private zoomHandler = () => {
        const zoomed = document.createElement("div");
        zoomed.className = "zoomed";

        const img = document.createElement("img");
        img.src = this.getAttribute("large-source");
        zoomed.append(img);
        
        document.body.append(zoomed);

        zoomed.addEventListener("click", () => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    zoomed.toggleAttribute("open", false);
                    setTimeout(() => {
                        zoomed.remove();
                    }, 300);
                });
            });
            
        });

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                zoomed.toggleAttribute("open", true);
            });
        });
    }


}

customElements.define(ZoomableImageElement.tag, ZoomableImageElement);