import "./WaitSpinnerElement.less";

export class WaitSpinnerElement extends HTMLElement implements Disposable {
    static tag = "wait-spinner";

    connectedCallback() {   
        this.innerHTML = this.view();
    }

    private view = () => `
        <div>
            <img src="${require("./Images/bigrim.gif")}" />
            Vent venligst...
        </div>
    `;

    public static show() {
        const spinner = new WaitSpinnerElement();

        document.body.appendChild(spinner);
        return spinner;
    }

    [Symbol.dispose]() {
        this.remove();
    }
}

customElements.define(WaitSpinnerElement.tag, WaitSpinnerElement);