import "./SiteBarElement-style";

class SiteBarElement extends HTMLElement {
    private lightPaperItems: LightPaperItem[];
    private lightPaperElement: HTMLDivElement;
    private current = 0;

    connectedCallback() {
        this.insertAdjacentHTML("beforeend", this.view());

        this.querySelector("button").addEventListener("click", () => {
            if (this.oeshop) 
                window.location.href = "/OeShop/Api/CancelOeShop";
            else
                window.location.href = "/LoginPage.aspx";
        });

        this.lightPaperElement = this.querySelector("[name=light-paper]") as HTMLDivElement;

        setTimeout(() => {
            this.setupLightPaper();
        }, 100);
    }

    private setupLightPaper() {
        const script = this.querySelector("script[type='application/json+newsitems']") as HTMLScriptElement;
        if (!script)
            return;

        this.lightPaperItems = JSON.parse(script.innerText) as LightPaperItem[];
        if (this.lightPaperItems?.length === 0)
            return;

        this.showNext();
    }


    private showNext = () => {
        if (this.current > this.lightPaperItems.length-1)
            this.current = 0;

        
        this.lightPaperElement.style.opacity = "0";


        setTimeout(() => {
            const item = this.lightPaperItems[this.current];
            this.lightPaperElement.innerHTML = `${item.link}${item.description}${item.linkEnd}`;
            this.lightPaperElement.style.opacity = "1";

            this.current++;
            setTimeout(() => this.showNext(), 4000);
        }, 1000);


    }

    private loggedIn = () => document.documentElement.hasAttribute("authendicated");

    private oeshop = () => document.documentElement.hasAttribute("oe-shop");

    private view = () => `
        <div name="frame">
            <div name=light-paper></div>
            ${this.oeshop() ? `<button type=button>${this.getAttribute("cancel-oe-text")}</button>` : `<button type=button>${this.loggedIn() ? this.getAttribute("log-out-text") : this.getAttribute("log-in-text")}</button>`}            
        </div>
    `;
}

interface LightPaperItem {
    description: string;
    url: string;
    link: string;
    linkEnd: string;
}

customElements.define("site-bar", SiteBarElement);