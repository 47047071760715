import "./PromptDialogElement-style";

export interface PromptOption {
    id: string;
    title: string;
    intent: "primary" | "secondary";
}

export class PromptDialogElement extends HTMLElement {
    private _header: string;
    private _question: string;
    private _options: PromptOption[];

    set header(value: string) {
        this._header = value;
    }

    set question(value: string) {
        this._question = value;
    }

    set options(value: PromptOption[]) {
        this._options = value;
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this.addEventListener("click", this.clickHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
    }

    public show() : Promise<string> {
        return new Promise(resolve => {
            this.addEventListener("selected", (event: CustomEvent) => { resolve(event.detail) }, { once: true });
            document.body.append(this);
        });
    }

    private clickHandler = (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const targetElement = event.target as Element;

        const button = targetElement.closest("button");
        if (!button)
            return;

        if (button.hasAttribute("option")) {
            this.dispatchEvent(new CustomEvent("selected", { detail: button.id }));
            this.remove();
        }
        
    }   

    private view = () => `
        <div>
            <header>${this._header}</header>
            <div class=question>
                ${this._question}
            </div>
            <div class=buttons>
                ${this._options.map(option => `
                    <button option type="button" id="${option.id}" ${option.intent}>${option.title}</button>
                `).join("")}
            </div>
        </div>
    `;
}

customElements.define("prompt-dialog", PromptDialogElement);