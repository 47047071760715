import "./EnergyLabelElement.less";

export class EnergyLabelElement extends HTMLElement {
    public static tag = "energy-label";

    connectedCallback() {
        this.innerHTML = this.view();
        this.addEventListener("pointermove", this.onPointerMove, { passive: true, once: true })
    } 

    disconnectedCallback() {
        this.removeEventListener("pointermove", this.onPointerMove)
    } 

    private onPointerMove = (event: PointerEvent) => {
        const outer = this.closest(".overflow-container") as HTMLElement;
        if (outer) {
            const style = getComputedStyle(outer);
            if (style.overflow === "hidden")
                outer.style.overflow = "visible";
        }
    }

    get fuel(): string {
        return this.getAttribute("fuel");
    }

    get wet(): string {
        return this.getAttribute("wet");
    }

    get noiseLevel(): number {
        return parseInt(this.getAttribute("noise-level"));
    }
    
    get decibel(): number {
        return parseInt(this.getAttribute("decibel"));
    }

    private outerFrame = () => { 

    }

    private showNewLabel = () => { 
        const eprelId = this.getAttribute("eprel-id");
        return eprelId && eprelId != "" &&  eprelId != "0";
    }

    private newHoverView = () => `
        <a href="https://eprel.ec.europa.eu/screen/product/tyres/${this.getAttribute("eprel-id")}" class=reveal target=_new>
            <img src="https://eprel.ec.europa.eu/label/Label_${this.getAttribute("eprel-id")}.png" onerror="this.style.display='none'" />
            <img src="${this.getAttribute("alt-image")}" class=alt-image />
        </a>
    `;

    private oldHoverView = () => `
        <div class=reveal target=_new>
            <img src="${this.getAttribute("alt-image")}" />
        </div>
    `;
    
    private view = () => `
        ${this.showNewLabel() ? `<a href="https://eprel.ec.europa.eu/screen/product/tyres/${this.getAttribute("eprel-id")}" target=_new>` : `<div>`}
            ${this.hasAttribute("ice-grip") ? `
                <div class=icegrip>
                    ${require("./Images/icegrip.svg")}
                    Ice
                </div>        
            ` : ""}
            ${this.hasAttribute("snow-grip") ? `
                <div class=snowgrip>
                    ${require("./Images/snowgrip.svg")}
                    Snow
                </div>        
            ` : ""}
            <div class="fuel">${this.fuel}</div>
            <div class="wet">${this.wet}</div>
            <div class="noise noise${this.noiseLevel}">
                <div class="number">${this.decibel}</div>
                <div class="db">dB</div>
            </div>
        ${this.showNewLabel() ? `</a>` : `</div>`}
        ${this.showNewLabel() ? this.newHoverView() : this.oldHoverView()}
    `;
}

customElements.define(EnergyLabelElement.tag, EnergyLabelElement);