export interface StockItem 
{
    description: string;
    reference: string;
    deliveryMessage: string;
    orderedBy: string;
    documentNumber: string;
    orderDate: string;
    expires: string;
    items: StockItem[];
    quantity: number;
    itemNo: string;
    itemId: number; 
    lineId: number;
    lineType: LineType;
    image: string;
    largeImage: string;
    id: string;
    quantityInBasket: number;
    salesPrice: number;
    totalSalesPrice: number;
    totalVATPrice: number;
    itemType: "Generic" | "Rim" | "Tyre" | "Accessory" | "Mounting" | "Related" | "Spare" | "Oil";
}


export enum LineType {
    other = 0,
    finance = 1,
    product = 2
}