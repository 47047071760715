import "./ProductDisplayElement.less";
import { MoneyAmountElement } from "../../features/Money"; 
import { ZoomableImageElement } from "../../features/MyStock";
import { AccessoryStockElement } from "./AccessoryStockElement";

export class ProductDisplayElement extends HTMLElement {
    static tag = "product-display";
    private _quantityDropdown: HTMLElement;

    connectedCallback() {
        this.innerHTML = this.view();
        this._quantityDropdown = this.querySelector("quantity-dropdown");

        this._quantityDropdown.setAttribute("max-value", this.maxOrder().toString());   

        if (this.getAttribute("categroy") === "Centreringsringe til alufælge") {
            const localStock = parseInt(this.getAttribute("local-stock"));

            this._quantityDropdown.setAttribute("value", Math.min(4, localStock).toString());
        }
           

        const buyButton = this.querySelector("buy-button");
        buyButton.setAttribute("quantity", this._quantityDropdown.getAttribute("value"));

        this._quantityDropdown.addEventListener("change", this.onQuantityChange);
    }

    
    disconnectedCallback() {
        this._quantityDropdown.removeEventListener("change", this.onQuantityChange);
    }

    private onQuantityChange = () => {    
        this.querySelector("buy-button").setAttribute("quantity", this._quantityDropdown.getAttribute("value"));
    }

    private maxOrder = () => {
        const localStock = parseInt(this.getAttribute("local-stock"));
        const remoteStock = parseInt(this.getAttribute("remote-stock"));
        const quantityInPurchase = parseInt(this.getAttribute("quantity-in-purchase"));
        const stockLevel = parseInt(this.getAttribute("stock-level"));
        const maxOrder = Math.max(localStock, remoteStock, quantityInPurchase);
        return maxOrder;
    }

    view = () => `
        <div class=image>

            <${ZoomableImageElement.tag} source="${this.getAttribute("image")}" large-source="${this.getAttribute("image")}" loading="lazy" allow-zoom></${ZoomableImageElement.tag}>
        </div>
        <div class=description>
            <b>${this.getAttribute("text-1")}</b><br/>
            ${this.getAttribute("text-2")}
        </div>
        <div class=item-id>Vare nr. ${this.getAttribute("item-id")}</div>
       
        <${AccessoryStockElement.tag} 
            local-stock=${this.getAttribute("local-stock")}
            remote-stock=${this.getAttribute("remote-stock")}
            quantity-in-purchase=${this.getAttribute("quantity-in-purchase")}
            stock-level=${this.getAttribute("stock-level")}
        ></${AccessoryStockElement.tag}>
    
        <div class=price>
            <div><span>Brutto</span> <${MoneyAmountElement.tag} minor="${this.getAttribute("gross-price")}"></${MoneyAmountElement.tag}></div>

            ${this.hasAttribute("net-price") ? `
                <div><span>Netto</span> <${MoneyAmountElement.tag} minor="${this.getAttribute("net-price")}"></${MoneyAmountElement.tag}></div>
            ` : ""}
        </div>
        <div class=buy>
            <quantity-dropdown value=1 max-value=${this.getAttribute("local-stock")} inventory-kind=local></quantity-dropdown>
            <buy-button item-id=${this.getAttribute("item-id")} quantity=${this.getAttribute("local-stock")}></buy-button>
        </div>
`;
}

customElements.define(ProductDisplayElement.tag, ProductDisplayElement);