import { PromptDialogElement } from "../../features/PromptDialog";
import "./TpmsBuyDialogElement.less";

interface AddTPMSRequest {
    licensePlate: string;
    type: string;
    color?: string;
    overrideNoTpms?: boolean;
}

export class TpmsBuyDialogElement extends HTMLElement {
    static tag = "tpms-buy-dialog";
    connectedCallback() {
        this.innerHTML = this.view();

        this.addEventListener("click", this.clickHandler)

    }

    private clickHandler = async (e: MouseEvent) => {
        const typeSelector = document.querySelector("[name=type]:checked") as HTMLInputElement;
        const colorArea = this.querySelector("[name=color-panel]") as HTMLElement;
        const buyButtonArea = this.querySelector("[name=buy-panel]") as HTMLElement;

        colorArea.toggleAttribute("show", typeSelector?.value === "alloy");

        const colorSelector = document.querySelector("[name=color]:checked") as HTMLInputElement;
        buyButtonArea.toggleAttribute("show", typeSelector?.value === "steel" || colorSelector?.value !== undefined);   
        
        if (e.target instanceof HTMLElement && e.target.closest("button")) {

            const button = this.querySelector("button") as HTMLButtonElement;

            const request: AddTPMSRequest = { licensePlate: this.getAttribute("license-plate"), type: typeSelector?.value, color: colorSelector?.value };

            try {
                button.disabled = true;
                let response = await this.addTPMS(request);

                if (!response.ok) {
                    const payload = await response.json();
                    if (payload?.Message === "Car without TPMS") {
                        const override = await this.overrideNoTpms();
                        if (override) {
                            request.overrideNoTpms = true;
                            response = await this.addTPMS(request);
                        } else {
                            this.remove();
                            return;
                        }
                    } else {
                        this.showNoCar();
                        this.remove();
                    }

                }

                if (response.ok)
                    window.location.href = "/Warehouse/Basket.aspx";
               
            } catch(error) {
                button.disabled = false;
            }
        }
    }

    private async overrideNoTpms() : Promise<boolean>  {
        const prompt = new PromptDialogElement();
        prompt.header = "Bil uden TPMS";
        prompt.question = `
            <p>Denne bil bruger iflg. vores oplysninger ikke direkte dæktryksovervågning (TPMS).</p>
            <p>Vil du stadig fortsætte med din bestilling?</p>
        `;
        prompt.options = [{ id: "no", intent: "secondary", title: "Nej, gå tilbage" }, { id: "yes", intent: "primary", title: "Ja, bestil TPMS" }];

        const response = await prompt.show();

        return (response === "yes");
    }

    private async showNoCar() : Promise<boolean>  {
        const prompt = new PromptDialogElement();
        prompt.header = "Bil ikke fundet";
        prompt.question = `
            <p>Bilen blev ikke fundet.</p>
        `;
        prompt.options = [{ id: "yes", intent: "primary", title: "Ok" }];

        const response = await prompt.show();

        return (response === "yes");
    }

    private addTPMS = async (request: AddTPMSRequest) => {
        return await fetch("/api/tpms", {
            method: "POST",
            body: JSON.stringify(request),
            credentials: "same-origin",
            headers: {
                "content-type": "application/json"
            }
        });
    }

    private view = () => `
        <div name=frame>
            <header>
                Køb TPMS til ${this.getAttribute("license-plate")}
            </header>
            <div name=type-panel>
                <label>Vælg fælg type</label>
                <div class=field><input name=type value=steel type=radio><label>Stål</label></div>
                <div class=field><input name=type value=alloy type=radio><label>Aluminium</label></div>
                
            </div>
            <div name=color-panel>
                <label>Vælg farve</label>
                <div class=field><input name=color value=chrome type=radio><label>Sølv/chrome</label></div>
                <div class=field><input name=color value=black type=radio><label>Sort</label></div>
            </div>

            <div name=buy-panel>
                <button type=button class="btn-green">Tilføj til kurv</button>
            <div>

        </div>`;

        //
}

customElements.define(TpmsBuyDialogElement.tag, TpmsBuyDialogElement);