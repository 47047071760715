import videojs from "video.js"
import "video.js/dist/video-js.css";
import "./VideoPlayerElement.less";

export class VideoPlayerElement extends HTMLElement {
    private player?: videojs.Player;
    private videoNode?: HTMLVideoElement;
    static tag = "video-player";

    connectedCallback() {
        console.log("here");
        this.innerHTML = this.view();
        this.videoNode = this.querySelector("video");
        this.player = videojs(this.videoNode);
    }

    disconnectedCallback() { 
        this.player?.dispose();
    }

    private getPath() {
        const url = this.getAttribute("source");

        if (!url)
            throw new Error("No url to play");

        if (url.startsWith("http"))
            return url;

        return `https://r2.eurowheels.dk/${url}`;
    }

    private view = () => `
        <video class="video-js vjs-big-play-centered" controls preload=auto data-setup='{"fluid": true}'>
            <source src="${this.getPath()}" type="video/mp4">
            <p class="vjs-no-js">
                For at se denne video kræves JavaScript. Overvej at opgradere til en web browser der
                <a href="https://videojs.com/html5-video-support/" target="_blank">håndterer HTML5 video</a>
            </p>
        </video>
          
    `;
}

customElements.define(VideoPlayerElement.tag, VideoPlayerElement);