import "./OneClickOrderElement-style"

class OneClickOrderElement extends HTMLElement {
    private form: HTMLFormElement;

    connectedCallback() {
        this.innerHTML = this.view();
        this.querySelector("[name=close]").addEventListener("click", () => this.remove());
        this.querySelector("input").focus();
        this.form = this.querySelector("form") as HTMLFormElement;
        this.form.addEventListener("submit", this.submit);
    }

    private submit = async (event: Event) => {

        event.preventDefault();

        const data = {
            orderedBy: (<HTMLInputElement>this.querySelector("[name=ordered-by]")).value,
            reference: (<HTMLInputElement>this.querySelector("[name=reference]")).value
        }

        const result = await fetch("/Checkout/OneClickOrder/SubmitOneClickOrder", {
            method: "post",
            body: JSON.stringify(data),
            credentials: "same-origin"
        });

        const response = await result.json();

        if (response.success === true) {
            location.href = `/Warehouse/ThankYou.aspx?DocID=${response.mongoWebOrderId}`;
        } else if (response.success === false) {
            alert(response.message);
        } else {
            console.log("unhandled", response);
        }
    }

    private view = () => `
        <div>
            <header>
                One click order
                <button name="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 27 27"><path d="M3.03 0L0 3.063 10.47 13.53 0 24l3.03 3.063 10.5-10.47L24 27.064 27.03 24 16.563 13.53l10.47-10.467L24 0 13.53 10.47 3.03 0z" /></svg>
                </button>
            </header>

            <form>
                <div>
                    <label for="ordered-by">Bestilt af</label>
                    <input type="text" id="ordered-by" name="ordered-by" placeholder="" required />

                    <label for="reference">Reference</label>
                    <order-requisition-validator target="#reference"></order-requisition-validator>
                    <input type="text" id=reference name=reference placeholder="" required />
                </div>

                <button type="submit">Bestil</button>
            </form>
        </div>
    `;
}

customElements.define("one-click-order", OneClickOrderElement);