import "./ImageAdElement.less"

interface Ad {
    openInNewWindow?: boolean;
    image: string;
    link: string;
    newWindow: boolean;
}

class ImageAdElement extends HTMLElement {
    static tag = "image-ad";
    private _ads: Ad[];
    private _currentAdIndex = 0;
    private _link: HTMLAnchorElement;
    private _imageContainer: HTMLElement;
    private _forwardButton: HTMLButtonElement;
    private _backButton: HTMLButtonElement;
    private _delay = 4000;

    private get savedPosition() {
        try {
            return parseInt(localStorage.getItem("imageAdPosition") ?? "0");
        } catch(e) {
            console.error(e);
        }
        return 0;
    }

    private set savedPosition(value: number) {
        try {
            localStorage.setItem("imageAdPosition", value.toString());
        }
        catch (e) {
            console.error(e);
        }
    }

    private readAds() {
        const scriptElement = this.querySelector("script");
        if (!scriptElement)
            return;

        const ads = JSON.parse(scriptElement.textContent) as Ad[];
        return ads;
    }

    private renderAd(ad: Ad, intial = false) {
        if (ad.link) 
        {
            this._link.setAttribute("href", ad.link);
            this._link.setAttribute("target", ad.openInNewWindow ? "_blank" : "_self");
        } else {
            this._link.removeAttribute("href");
        }

        if (intial) {
            this._imageContainer.style.backgroundImage = `url(${ad.image})`;
            return;
        }

        const tempElement = document.createElement("div");
        this._link.append(tempElement);
        tempElement.style.opacity = "0";
        tempElement.style.backgroundImage = `url(${ad.image})`;

        setTimeout(() => {
            tempElement.style.opacity = "1";

            setTimeout(() => {
                this._imageContainer.style.backgroundImage = `url(${ad.image})`;
                tempElement.remove();
            }, this._delay / 2);
        }, 100);
    }

    private view = (adCount: number) => `
        <a>
            <div></div>
            
        </a>

        ${adCount > 1 ? `
            <button class="none">${require("./Images/back.svg")}</button>
            <button class="none">${require("./Images/forward.svg")}</button>
        ` : ""}
    `;

    connectedCallback() {
        this._ads = this.readAds();

        if (!this._ads)
            return;

        this.innerHTML = this.view(this._ads.length);

        this._link = this.querySelector("a")!;
        this._imageContainer = this.querySelector("div")!;
        this._backButton = this.querySelector("button:nth-of-type(1)")!;
        this._forwardButton = this.querySelector("button:nth-of-type(2)")!;
        this._forwardButton?.addEventListener("click", this.forward);
        this._backButton?.addEventListener("click", this.back);


        this.addEventListener("pointerover", this.onPointerOver);
        this.addEventListener("pointerleave", this.onPointerOut);
       
        if (this._ads.length === 1)
        {
            this.renderAd(this._ads[0], true);
            return;
        }

        if (this.savedPosition < this._ads.length)
            this._currentAdIndex = this.savedPosition;
        else
            this._currentAdIndex = 0;

        
        // set index to a random value
        //this._currentAdIndex = Math.floor(Math.random() * this._ads.length);
        this.renderAd(this._ads[this._currentAdIndex]);
        this._timer = setTimeout(this.nextAd, this._delay);
    }

    private _timer: any;

    private forward = (event: Event) => {
        event.preventDefault();
        this._imageContainer.style.transition = "transition: opacity .1s ease"
        
        this._currentAdIndex++;
        if (this._currentAdIndex >= this._ads.length)
            this._currentAdIndex = 0;

        this.savedPosition = this._currentAdIndex;

        this.renderAd(this._ads[this._currentAdIndex], true);

        setTimeout(() => {
            this._imageContainer.style.transition = "";
        }, 500);
    }

    private back = (event: Event) => {
        event.preventDefault();
        this._imageContainer.style.transition = "transition: opacity .1s ease"
        
        this._currentAdIndex--;
        if (this._currentAdIndex < 0)
            this._currentAdIndex = this._ads.length - 1;

        this.savedPosition = this._currentAdIndex;

        this.renderAd(this._ads[this._currentAdIndex], true);

        setTimeout(() => {
            this._imageContainer.style.transition = "";
        }, 500);
    }

    private onPointerOver = () => clearTimeout(this._timer!);
    
    private onPointerOut = () => { 
        this._timer = setTimeout(this.nextAd, this._delay);
    }


    private nextAd = () => {
        this._currentAdIndex++;
        if (this._currentAdIndex >= this._ads.length)
            this._currentAdIndex = 0;

        this.savedPosition = this._currentAdIndex;
        
        this.renderAd(this._ads[this._currentAdIndex]);
        
        this._timer = setTimeout(this.nextAd, this._delay);
    }
}

customElements.define(ImageAdElement.tag, ImageAdElement);