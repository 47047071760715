import { BasketStore, Basket, Item } from "./BasketStore";
import "./BasketSummaryElement-style";

export class BasketSummaryElement extends HTMLElement {
    static tag = "basket-summary";

    private basketRefreshHandler = async (event: CustomEvent<Basket>) => await this.refresh();
    
    async connectedCallback() {
        this.showLoader();
        window.addEventListener("basket-refresh", this.showLoader);
        
        setTimeout(() => {
            BasketStore.instance.addEventListener("updated", this.basketRefreshHandler);
        });
    }

    disconnectedCallback() {
        BasketStore.instance.removeEventListener("updated", this.basketRefreshHandler);
    }

    private showLoader = () => {
        this.innerHTML = `<div class=loader><img src="/img/ajax-loader_BlackWhite.gif" /> Henter kurv ...</div>`;
    }

    private refresh = async () => {
        this.innerHTML = this.view(await BasketStore.instance.current());

        const scroller = this.querySelector("[name=item-view]") as HTMLElement;
        if (scroller)
            scroller.scrollTop = scroller.scrollHeight;
    }

    private view = (basket: Basket) => `
        <div name=item-view>${(basket?.items ?? []).map(item => this.itemView(item)).join("")}</div>
        <div class="price">
            <div>${basket?.priceSummary || ""}</div>
            <div>${basket?.total ? BasketStore.formatPrice(basket.total) : ""}</div>
        </div>`

    private itemView = (item: Item) => {
        if (item.itemType !== "Fee" && !item.itemId)
            return;

        return item.itemId >= 9000000 ? this.setView(item) : this.lineView(item);
    }

    private setView = (item: Item) => (item.items ?? [])?.map(item => this.itemView(item)).join("");
    
    private lineView = (item: Item) => `
        <div class="item"${item.itemId && item.itemId !== 1036 ? ` item-id="${item.itemId}"` : ""}${item.isRecent ? " new" : ""}>
            <span>${item.quantity > 0 ? `${item.quantity} x` : ""}</span>${item.description}
        </div>
        ${(item.items ?? []).map(item => this.itemView(item)).join("")}
    `;
}

customElements.define(BasketSummaryElement.tag, BasketSummaryElement);