import { BasketStore, Basket, Item } from "./BasketStore";
import * as headerElements from "../SiteHeader/HeaderElements-module.less";
import "./BasketViewer-style";

class BasketViewerElement extends HTMLElement {
    private oneClick = () => document.documentElement.hasAttribute("one-click");

    async connectedCallback() {
        this.className += ` ${headerElements["popup-box"]}`;
        await this.refresh();
        this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));

        var oneClickOrder = this.querySelector("[name=one-click-order]") as HTMLButtonElement;
        oneClickOrder?.addEventListener("mouseover", () => {
            oneClickOrder.innerText = oneClickOrder.getAttribute("hover-text");
        });
        oneClickOrder?.addEventListener("mouseleave", () => {
            oneClickOrder.innerText = oneClickOrder.getAttribute("text");
        });

        this.querySelector("[name=checkout]").addEventListener("click", () => {
            window.location.href = "/Warehouse/Basket.aspx";
        });

        this.querySelector("[name=one-click-order]")?.addEventListener("click", () => {
            document.body.append(document.createElement("one-click-order"));
            this.closeHandler();
        });

        this.querySelector("[name=one-click-deactivated]")?.addEventListener("click", () => {
            window.location.href = "/customer/settings.aspx";
            this.closeHandler();
        });

        document.addEventListener("popup", this.closeHandler);
        document.addEventListener("click", this.globalClickHandler);
    }

    disconnectedCallback() {
        document.removeEventListener("popup", this.closeHandler);
        document.removeEventListener("click", this.globalClickHandler);
    }

    private closeHandler = () => this.remove();

    private globalClickHandler = async (event) => {
        if (!(event.target instanceof Element))
            return;

        if (this.contains(event.target))
            return;

        this.remove();
    }

    private refresh = async () => {
        this.innerHTML = this.view(await BasketStore.instance.current());
    }

    private view = (basket: Basket) => `
       
        ${basket.items.map(item => this.itemView(item)).join("")}
        <hr />
        ${this.shippingView(basket.items.filter(item => item.itemType === "Generic" && item.itemId === 0)[0])}
        <hr />
        <div class="price">
            <div>${basket.priceSummary}</div>
            <div>${BasketStore.formatPrice(basket.total)}</div>
        </div>

        
        <button name=checkout>Se varekurv / Afslut køb</button>
        ${this.oneClick() ? `
            <button type="button" name="one-click-order" hover-text="${basket.deliveryText}" text="Bestil ordre NU!">Bestil ordre NU!</button>
        `: `
            <button type="button" name="one-click-deactivated">Bestil ordre NU! er deaktiveret. Aktivér her</button>
        `}
        
    `

    private shippingView = (item: Item) => `
        <div class="shipping">
            ${item ? item.description : ""}
        </div>
    `

    private itemView =  (item: Item) => {
        return item.itemId >= 9000000 ? this.setView(item) : this.lineView(item);      
        // switch(item.itemType) {
        //     case "Generic":
        //         return item.itemId >= 9000000 ? this.setView(item) : "";      
        //     default:
        //         return this.lineView(item);
        // }
    }

    private setView = (item: Item) => `
        <div class="set">
            ${item.description} 
        </div>
        ${item.items.map(item => this.itemView(item)).join("")}
    `;

    private lineView = (item: Item) => `
        <div class="item">
            ${item.quantity}x ${item.description} 
        </div>
    `;

}

customElements.define("basket-viewer", BasketViewerElement);