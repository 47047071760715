import { TpmsBuyDialogElement } from "./TpmsBuyDialogElement";
import "./BuyTpmsButtonElement.less";

class BuyTpmsButtonElement extends HTMLElement {
    static tag = "buy-tpms-button";

    private _button: HTMLButtonElement;

    connectedCallback() {
        this._button = document.createElement("button");
        this._button.setAttribute("type", "button");
        this._button.innerText = "Køb 4 TPMS";
        this._button.classList.add("nope");
        this._button.addEventListener("click", this.clickHandler);
        this.append(this._button)
    }

    disconnectedCallback() {
        this._button.removeEventListener("click", this.clickHandler);
    }

    private clickHandler = () => {
        const dialog = new TpmsBuyDialogElement();
        dialog.setAttribute("license-plate", this.getAttribute("license-plate"));
        document.body.append(dialog)
    };
}

customElements.define(BuyTpmsButtonElement.tag, BuyTpmsButtonElement);


