type Currency = "DKK";

export class MoneyAmountElement extends HTMLElement {
    static tag = "money-amount";
    private getMinor = () => parseInt(this.getAttribute("minor"));
    private getCurrency = () => (this.getAttribute("currency") ?? "none") as Currency;
    private random = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
    
    connectedCallback() {
        const amount = this.money(this.getMinor() / 100);
        this.innerText = this.formatCurrency(amount, this.getCurrency());
    }

    private formatCurrency(amount: string, currency: Currency) {
        switch (currency) {
            case "DKK": return `${amount} Kr.`;
            default: return amount;
        }
    }

    public money(amount: number) {
        const str = amount
                        .toFixed(2)
                        .replace(",", "")
                        .replace(".", "");

        const major = str
            .substring(0, str.length-2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        const formatted =  `${major},${str.substring(str.length-2)}`;

        if (!document.documentElement.hasAttribute("camouflaged-prices"))
        return formatted;
        return `${this.random(1,9)}.${major}:${this.random(0,9)}${this.random(0,9)}`;
    } 
}

customElements.define(MoneyAmountElement.tag, MoneyAmountElement);