import { PromptDialogElement } from "../PromptDialog";

export class OrderRequisitionValidatorElement extends HTMLElement {
    static tag = 'order-requisition-validator';
    private _targetElement: HTMLInputElement;

    connectedCallback() {
        this._targetElement = this.parentElement.querySelector(this.getAttribute('target')) as HTMLInputElement;

        if (!this._targetElement)
            throw new Error(`Target element not found, looking for ${this.getAttribute('target')}`);

        this._targetElement.addEventListener("blur", this.onBlur); 
    }

    disconnectedCallback() {
        this._targetElement.removeEventListener("blur", this.onBlur);
    }

    private formatDate = (source: string) => {
        const date = new Date(source);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    private onBlur = async () => { 
        if (this._targetElement.value === "")
            return

        const originalBackgroundColor = this._targetElement.style.backgroundColor;

        this._targetElement.style.backgroundColor = "#ccc";
        this._targetElement.readOnly = true;

        const requisition = await this.checkRequisition(this._targetElement.value);

        if (requisition.invoiceNo) {
            const dialog = new PromptDialogElement();
            dialog.header = "Bemærk";
            dialog.question = `Der er allerede oprettet en ordre med denne reference.<p>Fakturanummer ${requisition.invoiceNo} den ${this.formatDate(requisition.date)}</p>`;
            dialog.options = [
                {
                    id: "ok",
                    title: "OK",
                    intent: "primary"
                }
            ];

            await dialog.show();
            this._targetElement.setCustomValidity(`Der er allerede oprettet en ordre med denne reference.\nFakturanummer ${requisition.invoiceNo} den ${this.formatDate(requisition.date)}`)
        } else {
            this._targetElement.setCustomValidity("");
        }

        this._targetElement.style.backgroundColor = originalBackgroundColor;
        this._targetElement.readOnly = false;
    }

    async checkRequisition(reference: string) : Promise<OrderRequisitionViewModel | undefined> {
        const search = new URLSearchParams();
        search.append("reference", reference);

        const response = await fetch(`/api/order-requisition?${search.toString()}`);

        if (!response.ok)
            throw new Error("Failed to fetch order requisition")
        
    
        return await response.json() as OrderRequisitionViewModel;
    }
}

interface OrderRequisitionViewModel {
    invoiceNo?: string;
    date?: string;
}

customElements.define(OrderRequisitionValidatorElement.tag, OrderRequisitionValidatorElement);