import "./ImpersonateSelectorElement-style";
import * as headerElements from "../SiteHeader/HeaderElements-module.less";

interface Debtor {
    DebtorNo: string;
    CustomerId: number;
    Name: string;
    Address: string;
    Email: string;
    DebtorGroupDescription: string;
    Latitude:number;
    Longitude: number;
    Blocked: true;
    BlockedBy: string;
    BlockedNote: string;
    BlockedDateTime: string;
    BlockedDateTimeString: string;    

}

class ImpersonateSelectorElement extends HTMLElement {
    private suggestPanel: HTMLElement;
    connectedCallback() {
        this.className += ` ${headerElements["popup-box"]}`;
        this.innerHTML = this.view();

        const input = this.querySelector("input");
        input.addEventListener("input", this.suggest);

        this.suggestPanel = this.querySelector("[name=suggest]");

        this.addEventListener("click", this.showDebtor);
        this.addEventListener("mouseover", this.mouseHandler);

        input.focus();
        this.dispatchEvent(new CustomEvent("popup", { bubbles: true }));

        

        document.addEventListener("popup", this.closeHandler);
        document.addEventListener("click", this.globalClickHandler);
        document.addEventListener("keydown", this.keyboardHandler);

    }

    disconnectedCallback() {
        this.removeEventListener("click", this.showDebtor);
        this.removeEventListener("mouseover", this.mouseHandler);
        document.removeEventListener("popup", this.closeHandler);
        document.removeEventListener("click", this.globalClickHandler);
        document.removeEventListener("keydown", this.keyboardHandler);
        
    }

    private mouseHandler = (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;
        
        const target = event.target as Element;

        const li = target.closest("li");
        if (li) {
            var selected = this.querySelector("li[selected]");

            if (selected != li) {
                selected?.removeAttribute("selected");
                li.setAttribute("selected", "");
            }
        }
    }

    private closeHandler = () => {
        this.remove();  
    }

    private move = (delta: number) => {
        const lis = Array.from(this.querySelectorAll("li")) as HTMLElement[];
        const selected = this.querySelector("li[selected]") as HTMLElement;
        let index = selected ? lis.indexOf(selected) : -1;
        
        index = index + delta;
        if (index >= 0 && index <= lis.length-1) 
        {
            selected?.removeAttribute("selected");
            lis[index].setAttribute("selected", "");
        }
    }

    private keyboardHandler = async (event: KeyboardEvent) => {
        switch (event.key) 
        {
            case "Escape": 
                this.remove();
                break;
            case "Enter": 
                await this.showDebtor();
                break;
            case "ArrowUp": 
                this.move(-1);
                break;
            case "ArrowDown":
                this.move(1);
                break;
        }

        
    }

    private globalClickHandler = async (event) => {
        if (!(event.target instanceof Element))
            return;

        if (this.contains(event.target))
            return;

        this.remove();
    }

    private showDebtor = async () => {
        var debtorNumber = this.querySelector("li[selected]:not([blocked])")?.getAttribute("debtor-number");
        if (!debtorNumber)
            return;

        var params = new URLSearchParams();
        params.append("debtorNo", debtorNumber); 

        var result = await fetch(`/AdminApi/Impersonate/Impersonate?${params.toString()}`, {
            method: "post",
            credentials: "include"
        });

        if (result.ok)
            window.location.reload();
        else
            alert(`Failed: ${result.statusText}`);

    }

    private suggest = async (event: InputEvent) => {
        var element = event.target as HTMLInputElement;

        if (element.value.length <= 2)
            return;

        var params = new URLSearchParams();
        params.append("DebtorSearch", "1");
        params.append("term", element.value);
        
        var result = await fetch(`/code/ajax.aspx?${params.toString()}`, {
            credentials: "include"
        });

        if (!result.ok) 
            return;

        var debtors = await result.json() as Debtor[];
        this.suggestPanel.innerHTML = debtors.map(this.debtorView).join("");
    }

    private view = () => `
        <div>
            <label for=debtor>Debitor</label>  
            <input type=text id=debtor placeholder=Debtor autocomplete="off" />
        </div>
        <ul name=suggest>
        
        </ul>
    `;

    private debtorView = (debtor: Debtor) => `
        <li ${debtor.Blocked ? "blocked" : ""} debtor-number="${debtor.DebtorNo}">
            <div class="pri">
                <b>${debtor.Name}</b><br>
                <span class="autocomplete-muted">${debtor.DebtorNo} ${debtor.DebtorGroupDescription || ""}<br>${debtor.Address}</span>
            </div>
            ${debtor.Blocked ? 
                `<div class="sec">
                    Spærret ${debtor.BlockedBy ? ` af ${debtor.BlockedBy}` : ""}
                    ${debtor.BlockedDateTimeString ? ` d. ${debtor.BlockedDateTimeString}` : ""}<br>
                    ${debtor.BlockedNote ? `Note: ${debtor.BlockedBy}`: ""}
                </div>`
                : ""}
        </li>
    `
}

customElements.define("impersonate-selector", ImpersonateSelectorElement);