import { PromptDialogElement } from "./PromptDialogElement";

class PromptDialogActivator {
    private static _instance = new PromptDialogActivator();

    public static get instance() { return PromptDialogActivator._instance; } 

    constructor() {
        document.addEventListener("DOMContentLoaded", this.loadMessageHandler);
        window.addEventListener("user-message", this.messageHandler)
    }

    private loadMessageHandler = () => {
        var json = document.documentElement.querySelector("script[type='application/json+messages']")?.innerHTML;

        if (json && json.length > 0)
            this.showMessages(JSON.parse(json));
    }

    private showMessages = (messages: string[]) => {
        if (!messages || messages.length === 0)
            return;

        const dialog = new PromptDialogElement();

        dialog.header = "Bemærk";
        dialog.question = messages.join("<br />");

        dialog.options = [
            { 
                id: "ok",
                title: "OK",
                intent: "primary"
            }
        ];

        document.body.append(dialog);
    }

    private messageHandler = (event: CustomEvent<string[]>) => {
       this.showMessages(event.detail);
    }
}


var ___ = PromptDialogActivator.instance;