import { ElementDataWatcher } from "../Helpers/ElementDataWatcher";
import { StockItem, LineType } from './StockItem';
import { MyStockStore } from './MyStockStore';
import "./OrderControlElement-style";

class OrderViewElement extends HTMLElement {
    private dataWatcher: ElementDataWatcher<StockItem>;
    private addToBasketButton: HTMLButtonElement;

    connectedCallback() {
        this.className += " expandable";

        this.dataWatcher = new ElementDataWatcher<StockItem>(this, "stock-item");
        this.refresh();

        const quantityInBasket = this.dataWatcher.data.quantityInBasket;
        if (quantityInBasket)
            this.setAttribute("quantity-in-basket", quantityInBasket.toString());

        this.addEventListener("click", this.clickHandler);
    }

    private clickHandler = (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const orderText = event.target.closest(".order-text");
        if (!orderText)
            return;
            
        var button = orderText.querySelector("button");
        
        var expandable = event.target.closest(".expandable");
        if (expandable) {
            if (expandable.toggleAttribute("open"))
                button.innerText = "Kollaps";
            else 
                button.innerText = "Udvid";
        }
    }

    private gatherLineIds(lineIds: number[], items: StockItem[]) {
        if (!items)
            return;

        items.forEach(item => {
            if (item.lineId)
                lineIds.push(item.lineId);
            this.gatherLineIds(lineIds, item.items);
        });
    }

    private refresh = () => {
        const existingChildren = Array.from(this.querySelectorAll("*:not(script)"));
        existingChildren.forEach(m => m.remove());

        this.insertAdjacentHTML("beforeend", this.view(this.dataWatcher.data));
        this.addToBasketButton = this.querySelector("[name=add-to-basket]");
        this.addToBasketButton.addEventListener("click", this.addToBasket);
    }

    private addToBasket = async () => {
        const item = this.dataWatcher.data;
        const lineIds = [];
        this.gatherLineIds(lineIds, item.items);

        const result = await MyStockStore.instance.addToBasket(item.documentNumber, item.id, item.quantity);

        if (!result)
            return;


        this.setAttribute("quantity-in-basket", item.quantity.toString());
        item.quantityInBasket = item.quantity;
        this.dataWatcher.data = item;
        this.refresh();

        // request : redirect to basket
        window.location.href = "/Warehouse/Basket.aspx";
    };

    private formatDate = (source: string) => {
        const date = new Date(source);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    private countRecursive(stockItem: StockItem) : number {
        let count = 1;

        for (const item of (stockItem.items ?? []).filter(this.productsFilter)) {
            count += this.countRecursive(item)
        }

        return count;
    }

    private view = (stockItem: StockItem) => `
        <div class="order-head children-${this.countRecursive(stockItem)}">
            <div class="order-text">
                <span>${stockItem.documentNumber}: ${stockItem.description}&nbsp;</span>&nbsp;
                Bestilt ${this.formatDate(stockItem.orderDate)}${stockItem.orderedBy ? ` af ${stockItem.orderedBy}` : ""}.
                Kan opbevares indtil ${this.formatDate(stockItem.expires)}.
                ${stockItem.reference ? `Ref. ${stockItem.reference}` : ""}
                <button type=button title="Udvid" class="expand-button">Udvid</button>
            </div>
            <div class=delivery-message>
                ${stockItem.deliveryMessage ? stockItem.deliveryMessage.replace(/(?:\r\n|\r|\n)/g, '<br>') : ""}
            </div>
            
            <div class=order-detail>
                <div class=order-lines>
                    <header>
                        <div class=count>
                            Antal
                        </div>
                        <div class=image>
                        
                        </div>
                        <div class=item-id></div>
                        <div class=description>Varebeskrivelse</div>
                        <div class=price>Stk. pris</div>
                        <div class=total>I alt</div>
                    </header>
                    ${stockItem.items ? stockItem.items.map(item => this.itemLine(item)).join("") : ""}
                </div>
                <div class=order-total>
                    <h2>Total</h2>
                    <div class=total-excluding-vat>
                        <div>Netto</div>
                        <money-amount minor=${stockItem.totalSalesPrice}></money-amount>
                    </div>
                    <div class=vat>
                        <div>Moms</div>
                        <money-amount minor=${stockItem.totalVATPrice}></money-amount>
                    </div>
                    <div class=total-including-vat>
                        <div>I alt</div>
                        <money-amount minor=${stockItem.totalVATPrice + stockItem.totalSalesPrice}></money-amount>
                    </div>
                    <button type=button title="Tilføj til kurven" class="basket-button" ${stockItem.quantityInBasket > 0 ? "disabled" : ""} name=add-to-basket>${require("../../images/shopping-cart-solid.svg")} Levér ordre</button>
                </div>
            </div>
        </div>
    `;


    private commentView = (stockItem: StockItem) => `
        <div>${stockItem.description}</div>
    `;

    private commentFilter = (stockItem: StockItem) => {
        if (!stockItem.description)
            return false;

        if (stockItem.itemNo)
            return false;

        return true;
    }

    private productsFilter = (stockItem: StockItem) => {
        if (!stockItem.itemNo)
            return false;

        // if (stockItem.lineType === LineType.finance)
        //     return false;

        return true;
    }

    private getImage(stockItem: StockItem) {
        if (stockItem.lineType === LineType.finance)
            return undefined;

        if (stockItem.itemType === "Related")
            return undefined

        if (stockItem.itemType === "Mounting")
            return "/img/mounting-MON.png";

        return stockItem.image;
        
    }

    private allowZoom(stockItem: StockItem) {
        if (stockItem.lineType === LineType.finance)
            return false;

        if (stockItem.itemType === "Related")
            return false

        if (stockItem.itemType === "Mounting")
            return false;

        return stockItem.largeImage !== undefined && stockItem.largeImage !== "";
        
    }

    private itemLine = (stockItem: StockItem) => `
        <div class="order-line">
            <div class=count>
                <div>${stockItem.quantity}</div>
            </div>
            <div class=image>
                ${this.getImage(stockItem) ? `<zoomable-image source="${this.getImage(stockItem)}" large-source="${stockItem.largeImage}" ${this.allowZoom(stockItem) ? "allow-zoom" : ""}></zoomable-image>` : ``}
            </div>
            <div class=item-id>${stockItem.itemId || ""}</div>
            <div class=description>
                ${stockItem.description || ""}
                ${stockItem.items ? stockItem.items.filter(this.commentFilter).map(item => this.commentView(item)).join("") : ""}
            </div>
            <money-amount class=price minor="${stockItem.salesPrice}"></money-amount>
            <money-amount class=total minor="${stockItem.salesPrice*stockItem.quantity}"></money-amount>
        </div>
        ${stockItem.items ? stockItem.items.filter(this.productsFilter).map(item => this.itemLine(item)).join("") : ""}`;
}

           


customElements.define("order-control", OrderViewElement);