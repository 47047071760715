import "./TyreAddedElement.less";
import { BasketSummaryElement, BasketUpsaleElement, QuickOrderElement } from '../BasketViewer';

export class TyreAddedElement extends HTMLElement { 
    static tag = "tyre-added";
    private closeHandler = () => this.remove();

    connectedCallback() { 
        this.innerHTML = this.view();

        Array.from(this.querySelectorAll(".js-close")).forEach(element => { 
            element.addEventListener("click", this.closeHandler);
        });
    }

    disConnectedCallback() { 
        Array.from(this.querySelectorAll(".js-close")).forEach(element => { 
            element.removeEventListener("click", this.closeHandler);
        });
    }

    private view = () => `
        <section>
            <header>
                <h3>Føjet til varekurv</h3>
                <button class=js-close type=button>X</buton>
            </header>
            <div>
                <${BasketSummaryElement.tag}></basket-summary>
                <div class="buttons">
                    <button type=button title="Dette vindue lukker og du kan handle videre" class=js-close>Køb mere</button>
                    <form action="/Warehouse/Basket.aspx" method=get>
                        <button type=submit title="Du føres til varekurven" class="no">Gå til kurv</button>
                    </form>
                </div>
                <${BasketUpsaleElement.tag}></${BasketUpsaleElement.tag}>
                
                <${QuickOrderElement.tag}></${QuickOrderElement.tag}> 
            </div>
        </section>
    `;


}

customElements.define(TyreAddedElement.tag, TyreAddedElement);