import { StockItem } from './StockItem';
import * as navigatorStyle from "./StockNavigatorElement-module.less";
import { MyStockStore } from './MyStockStore';
import * as myStockStyle from "./MyStock-module.less";

class StockNavigatorElement extends HTMLElement
{
    private input: HTMLInputElement;

    connectedCallback() {
        this.className += ` ${navigatorStyle["stock-navigator"]}`
        this.innerHTML = this.view();
        
        this.input = this.querySelector("input");
        this.input.addEventListener("input", this.inputHandler);
    }

    disconnectedCallback() {
        this.input.removeEventListener("input", this.inputHandler);
    }

    private inputHandler = () => {
        MyStockStore.instance.setFilter(this.input.value);
    } 

    private view = () => `
        <h1 class="${myStockStyle.logo}">${require("../../images/cubes-solid.svg")}MyStock</h1>
        <div>
            <input type="text" placeholder="Filtrér liste" />
        </div> 
    `; // <button>Tilføj 4 varer til kurv</button>
} 


customElements.define("stock-navigator", StockNavigatorElement);