import "./DeliveryDelayElement.less"

export class DeliveryDelayElement extends HTMLElement {
    private _submitButton: HTMLButtonElement;
    private _deliveryDate: HTMLInputElement;
    private _content: Node;
    private _minimumDate: string;

    public set content(value: Node) {
        this._content = value;
    }

    public set minimumDate(value: string) {
        this._minimumDate = value;
    }

    connectedCallback() {
        this.innerHTML = this.view();

        // grab elements
        this._deliveryDate = this.querySelector("[name=delivery-date]");

        const contentElement = this.querySelector("[name=content]");
        this._submitButton = this.querySelector("[name=accept-order]");

        this._submitButton.addEventListener("click", this.acceptDelay);

        contentElement.append(this._content);

        (<any>window).$(this._deliveryDate).datepicker({
            onSelect: this.inputHandler,
            constrainInput: true,
            dateFormat: 'dd-mm-yy',
            minDate: this._minimumDate,
            beforeShowDay: function (date) {
                var day = date.getDay();
                return [(day != 0 && day != 6), ''];
            }
        });

        this._deliveryDate.addEventListener("change", this.inputHandler);
    }

    private inputHandler = () => {
        this._submitButton.toggleAttribute("disabled", false);
    }

    private acceptDelay = () => {
        this.dispatchEvent(new CustomEvent<EventArgs>("accept-delay", { 
            detail: {
                message: `Skal bruges ${this._deliveryDate.value}`,
                requestedDate: this._deliveryDate.value
            }
        }));
    }


    private view = () => `
        <div>
            <div name=content></div> 
            
            <label>
                Ønsket leveringsdato
                <input name=delivery-date type=text placeholder="Vælg dato">
            </label>

            <div class=buttons>
                <button name=accept-order type=button disabled >Gennemfør ordre</button>
            </div>
        </div>

        
    `;
}


customElements.define("delivery-delay", DeliveryDelayElement);

export interface EventArgs {
    message: string,
    requestedDate: string;
}