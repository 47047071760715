import EventTarget from "@ungap/event-target";

interface EventMap {
    "filter-change": FilterValue;
}

export interface FilterValue {
    text: string;
}

export class MyStockStore {
    private static _instance = new MyStockStore();
    private constructor() {};
    public static get instance() { return this._instance; } 
    private eventTarget = new EventTarget();

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { ["sender"] : "MyFeedStore" })})); 
    }

    public setFilter(filter: string) {
        this.emit("filter-change", { text: filter });
    }

    public async addToBasket(documentNumber: string, stockItemId: string, quantity: number) : Promise<boolean> {

        var data = {
            "documentNumber": documentNumber,
            "id": stockItemId,
            "quantity": quantity
        };

        const response = await fetch("/my-stock/AddToBasket", {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data),
            credentials: "same-origin"
        });

        if (!response.ok)
            throw new Error(`Unable to update inventory status: ${response.statusText}`);
        else
            setTimeout(this.refreshBasket, 100);

        return response.ok;
    }

    private refreshBasket = () => {
        let event;
        if (typeof (Event) === 'function') {
            event = new Event('basket-refresh');
        } else {
            event = document.createEvent('Event');
            event.initEvent('basket-refresh', true, true);
        }
    
        window.dispatchEvent(event);
    }
}