import { TyreAddedElement } from "./TyreAddedElement";

class TyreAddedBasketPopupActivactor { 
    private static _instance = new TyreAddedBasketPopupActivactor();

    constructor() { 
        window.addEventListener("basket-added", this.basketAddedHandler);
    }

    private basketAddedHandler = (event: CustomEvent<ItemAdded>) => { 
        console.log("Added", event.detail);
        if (event.detail.type.indexOf("tyre") != -1) { 
            document.body.append(new TyreAddedElement());
        }
    }
}

type ProductType = "tyre" | "rim";

interface ItemAdded { 
    type: ProductType[];
}

