
export interface EventMap {
    "products-updated": ProductList;
}

export class ProductsStore {
    private _eventTarget = new EventTarget();

    static _instance = new ProductsStore();
    static get instance() { return ProductsStore._instance; }
    private _productTypes: { [key: number]: string } = {};

    constructor() {
        this.addEventListener("products-updated", this.updateUrl);
    }

    private updateUrl = (event: CustomEvent<ProductList>) => {

        const request = event.detail.request;
        let newURL = `${window.location.protocol}//${window.location.host}/accessories`;

        if (request.accessoryType)
            newURL += `/${request.accessoryType}`;

        const url = new URL(newURL)

        if (request.text)
            url.searchParams.set("text", request.text);
        else
            url.searchParams.delete("text");

        window.history.pushState({}, "", url.toString());
    }

    public getProducts() {
        var jsonCarrier = document.querySelector("script[type='application/json+products']") as HTMLScriptElement;

        if (!jsonCarrier)
            return undefined;

        const result =  JSON.parse(jsonCarrier.innerText) as ProductList;
        this._productTypes = result.accessoryTypes;
        return result;
    }

    public getProductTypes() {
        return this._productTypes;
    }

    public applyFilter = async (accessoryType?: number, text?: string) => {
        const url = `/accessories${accessoryType  !== undefined ? `/${accessoryType}` : ""}`;

        const urlSearch = new URLSearchParams();
        if (text)
            urlSearch.set("text", text);
       
        const response = await fetch(`${url}?${urlSearch.toString()}`, {
            headers: {
                "Content-Type": "application/json",
                accept: "application/json"
            },
        });

        if (response.ok) {
            const json = await response.json() as ProductList;
            this.emit("products-updated", json);
        }
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this._eventTarget.dispatchEvent(new CustomEvent(type, { detail: value }));
    }
}

export interface ProductList {
    products: Product[];
    accessoryTypes?: { [key: number]: string } ;
    request: SearchRequest;
}

export interface SearchRequest {
    accessoryType?: number;
    text?: string;
}

export interface Product { 
    itemId: number;
    itemNo: string;
    smallImage: string
    largeImage: string
    text1: string;
    text2: string;
    grossPrice: number;
    netPrice: number;
    localStock: number;
    remoteStock: number;
    quantityInPurchase: number;
    stockLevel: number;
    category: string;
}