import "./MyStockIntroductionElement-style";
import * as myStockStyle from "./MyStock-module.less";

class MyStockIntroductionElement extends HTMLElement {
    private checkbox: HTMLInputElement;
    connectedCallback() {

        if (this.getCookie("mystock-intro") === "false") {
            this.closeHandler();
            return;
        }

        this.innerHTML = this.view();
        this.checkbox = this.querySelector("input");
        this.checkbox.addEventListener("click", this.saveSetting);

        this.querySelector("[name=close]").addEventListener("click", this.closeHandler);
        this.querySelector("button.primary").addEventListener("click", this.myStockHandler);
    }

    private myStockHandler = (event: MouseEvent) => {
        event.preventDefault()
        window.location.href = "/my-stock";
    }

    private closeHandler = () => this.remove();

    private saveSetting = () => {
        this.setCookie("mystock-intro", "false");
    }

    private view = () => `
        <div class="panel">
            <h1 class="${myStockStyle.logo}">${require("../../images/cubes-solid.svg")}MyStock</h1>
            <div>
                <p>Når du bestiller til MyStock så beholder vi varerne på lageret indtil at du skal bruge dem.</p>
                <p>I løbet af 5 minutter vil du kunne se varerne på MyStock oversigten og bestille til levering.</p>
            </div>
            <div name=buttons>
                <button name=close class=secondary>Luk</button>
                <a href="/my-stock"><button class=primary>Gå til MyStock</button></a>
            </div>
            <div>
                <label>
                    <input type="checkbox">
                    Vis ikke denne besked næste gang
                </label>
            </div>
        </div>
    `;

    private setCookie = (name: string, val: string) => {
        const date = new Date();
        const value = val;

        // Set it expire in 365 days
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

        // Set it
        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    }

    private getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
}

customElements.define("my-stock-introduction", MyStockIntroductionElement);