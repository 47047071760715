import { DeliveryDelayElement, EventArgs } from "./DeliveryDelayElement";

class DeliveryDelayActivator {
    private static _instance = new DeliveryDelayActivator();

    private constructor() {
        window.addEventListener("basket-submit", (event: CustomEvent<BasketSubmitDetail>) => {
            const templateElement = <HTMLTemplateElement>document.querySelector("[js-field=delivery-delay-message]");
            if (templateElement?.hasAttribute("enabled") !== true)
                return;

            if (event.detail.requestedDelivery !== RequestedDelivery.Soon)
                return;

            event.preventDefault();

            const deliveryDelayElement = new DeliveryDelayElement();
            deliveryDelayElement.minimumDate = event.detail.earliestDeliveryDate;
            deliveryDelayElement.content = templateElement.content.cloneNode(true);
            document.body.append(deliveryDelayElement);

            deliveryDelayElement.addEventListener("accept-delay", (acceptEvent: CustomEvent<EventArgs>) => {
                const hidden = document.querySelector("[js-field=agreed-note]") as HTMLInputElement;
                hidden.value = acceptEvent.detail.message;

                const accuracyElement = document.querySelector(".deliveryDateDesiredAccuracy") as HTMLSelectElement;
                accuracyElement.value = "3";

                const desiredElement = document.querySelector(".deliveryDateDesired") as HTMLInputElement;
                desiredElement.value = acceptEvent.detail.requestedDate;

                event.detail.successCallback();
            });
        });
    }
}



interface BasketSubmitDetail {
    successCallback: () => void;
    requestedDelivery: RequestedDelivery;
    earliestDeliveryDate: string;
}

enum RequestedDelivery {
    Soon = 4
}