export class StockIndicatorElement extends HTMLElement { 
    static tag = "stock-indicator";

    localStock = () => parseInt(this.getAttribute("local-stock") ?? "0");

    remoteStock = () => parseInt(this.getAttribute("local-stock") ?? "0");

    quantityInPurchase = () => parseInt(this.getAttribute("quantity-in-purchase") ?? "0");

    connectedCallback() { 
        //console.log("sl", this.hasAttribute("stock-level"));

        if (!this.hasAttribute("stock-level"))
            this.setAttribute("stock-level", this.stockIndicator());
    
        this.innerHTML = `
            ${this.localStock() >= 20 ? `> 20` : this.localStock()}
            <img width='16' height='11' src='//r2.eurowheels.dk/img/stockrect${this.getAttribute("stock-level")}.png' />
        `;
    }

    private stockIndicator = () => {
        const localStock = this.localStock();   
        //decimal disp = item.LocalStockAvailable;
        if (localStock >= 4)
            return "40"; // Green - plenty

        if (localStock >= 1)
            return "20"; // Yellow

        // if (this.remoteStockAvailable >= 4)
        // {
        //     if (item.RemoteDelivery?.Date <= DateTime.UtcNow.NextWeekday())
        //         return "40";
        // }

        // if (this.quantityInPurchase >= 4)
        //     return "10"; // Orange - coming
        // if (disp > 0)
        //     return "20"; // Yellow - few

        // if (item.RemoteStockAvailable >= 4)
        // {
        //     return "30"; // Blue - remote
        // }
        return "0"; // Red - none
    }
}

customElements.define(StockIndicatorElement.tag, StockIndicatorElement);

