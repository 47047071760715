import "./UserCommentElement.less";
import { UserCommentEditorElement } from "./UserCommentEditorElement";

class UserCommentElement extends HTMLElement {
    private _userComment: UserCommentEditorElement;

    connectedCallback() {
        if (!this.hasAttribute("enabled"))
            return;

        this.refresh();
        this.addEventListener("click", this.clickHandler);
        this.addEventListener("comment-saved", this.commentSavedHandler);
    }

    private refresh = () => {
        if (!this.getAttribute("comment") || this.getAttribute("comment") == "")
            this.toggleAttribute("comment", false);
        
        this.innerHTML = this.view();
    }

    private clickHandler = (event: PointerEvent) => {
        if (!(event.target instanceof Element))
            return;

        const element = event.target as Element;

        if (element.closest(UserCommentEditorElement.tag))
            return;
        
        // if (this._userComment?.parentElement)
        //     return;

        

        this._userComment = new UserCommentEditorElement();
        this._userComment.lineId = this.getAttribute("line-id");
        this._userComment.text = this.getAttribute("comment");
        this.append(this._userComment);
    }

    private commentSavedHandler = (event: CustomEvent<string>) => {
        this.setAttribute("comment", event.detail);
        this.refresh();
    }


    private view = () => `
        <div name=comment>${this.getAttribute("comment") || ""}</div>
        <div name=buttons>
            ${this.hasAttribute("comment") ?
            `<button class="nope" type=button>${require("!!raw-loader!../../images/edit.svg").default}</button>` :
            `<button class="nope" name=add type=button>${require("!!raw-loader!../../images/note.svg").default}Tilføj note</button>`}
            
        </div>
    `

}

customElements.define("user-comment", UserCommentElement);