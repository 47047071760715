export type SortNames = "stockandprice";

export class SortStore {
    private static _instance = new SortStore();
    public static get instance() {return SortStore._instance }

    private _rimSortRecipes: SortRecipe<Rim>[] = [];

    public register(sortRecipe: SortRecipe<Rim>) {
        if (this._rimSortRecipes.some(m => m.sortName == sortRecipe.sortName))
            throw new Error(`SortRecipe name already registered: ${sortRecipe.sortName}`);

        console.log("registering", sortRecipe.sortName);

        this._rimSortRecipes.push(sortRecipe);
        return this;
    }

    public sort(sortName: SortNames, rims: Rim[]) {
        const recipe = this._rimSortRecipes.filter(m => m.sortName == sortName)[0];
        if (!recipe)
            throw new Error(`Sort recipe not found: ${sortName}. Available recipes: ${this._rimSortRecipes.map(m => m.sortName).join(",")}.`)

        // clone
        //rims = JSON.parse(JSON.stringify(rims));

        return recipe.performSort(rims);
    }

    public compare(rimsA: Rim[], rimsB: Rim[]) {

        const jsonA =  JSON.stringify(rimsA);
        const jsonB =  JSON.stringify(rimsB);

        const same = jsonA == jsonB;

        console.log("Are they equal", same, rimsA, rimsB);
    }
}

export interface Rim {
    rm: number; // Rim Material
    si: number; // Stock index
    ps: number; // Pris sort
    m: string;
    manufacturerCode: string;
}

export interface SortRecipe<TEntity> {
    sortName: SortNames;
    performSort(items: TEntity[]): TEntity[];
}

/*
    Sorts rims by material, stock manufacturer and price
*/
class StockAndPriceSortRecipe implements SortRecipe<Rim> {
    private group1 = ["MAK", "RIA", "ETW", "PRL"];
    public get sortName() { return "stockandprice" as SortNames }; 
    performSort(items: Rim[]): Rim[] {

        items.sort((rimA: Rim, rimB: Rim) => {
            // first sort steel before alu
            const materialOrder = rimA.rm - rimB.rm;

            // sort by stock status, the colored dots
            const stockOrder = rimA.si - rimB.si;

            // within the 'blue' group we want 'group1' manufacturers first, then sort by the rest
            let groupSort = 0;
            if (rimA.si == 6 && stockOrder == 0) {
                const rimAIsGroup1 = this.group1.indexOf(rimA.manufacturerCode) != -1;
                const rimBisGroup1 = this.group1.indexOf(rimB.manufacturerCode) != -1;
                
                if (rimAIsGroup1 && !rimBisGroup1)
                    groupSort = -1;
                
                if (!rimAIsGroup1 && rimBisGroup1)
                    groupSort = 1;
            }

            // sort by price
            const priceOrder = rimA.ps - rimB.ps;

            return materialOrder ||
                   stockOrder ||
                   groupSort ||
                   priceOrder;
        });

        return items;
    }
}
SortStore.instance.register(new StockAndPriceSortRecipe());

// Add as global to window so we can easy call it from legacy code
(<any>window).sortStore = SortStore.instance;
