import "./NotificationEditorElement.less";

class NotificationEditorElement extends HTMLElement {
    private _enablePhoneNotification: HTMLInputElement;
    private _notificationPhoneNumber: HTMLInputElement;
    private _enableEmailNotification: HTMLInputElement;
    private _notificationEmailAddress: HTMLInputElement;

    connectedCallback() { 
        
        this._enablePhoneNotification = document.querySelector("[js-id=enablePhoneNotification]");
        this._notificationPhoneNumber = document.querySelector("[js-id=notificationPhoneNumber]");
        this._enableEmailNotification = document.querySelector("[js-id=enableEmailNotification]");
        this._notificationEmailAddress = document.querySelector("[js-id=notificationEmailAddress]");


        this._enablePhoneNotification.addEventListener("change", this.refresh);
        this._enableEmailNotification.addEventListener("change", this.refresh);
        this.refresh();
    }

    disconnectedCallback() {
        this._enablePhoneNotification.removeEventListener("change", this.refresh);
        this._enableEmailNotification.removeEventListener("change", this.refresh);
    }

    private refresh = () => {
        this._notificationPhoneNumber.disabled = !this._enablePhoneNotification.checked;
        this._notificationEmailAddress.disabled = !this._enableEmailNotification.checked;
    }
}

customElements.define("notification-editor", NotificationEditorElement);