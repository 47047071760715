import { EnergyLabelElement } from "../EnergyLabels";
import "./TyreDisplayElement.less";


/*
    Meant to take over and show all tyre details but we are starting small
*/
export class TyreDisplayElement extends HTMLElement {
    static tag = "tyre-display";

    connectedCallback() {
        this.innerHTML = this.view();
    }
    
    private view = () => `
        <div class=details>
            <div>
                ${this.getAttribute("text-1")}
                ${this.getAttribute("text-2")}
            </div>
            <div>
                (${this.getAttribute("load-index-max")} kg/${this.getAttribute("speed-text")}) 
                ${this.eprelView(this.getAttribute("eprel-id"))}
            </div>
        </div>
        <div class=overview>
            <div>
                ${this.getAttribute("item-id")}
            </div>
            <div class=badges>
                <div name=offer>Tilbud</div>
                <div name=bestseller>Bestseller</div>
                <div name=news>Nyhed</div>
            </div>
        </div>
        <${EnergyLabelElement.tag}
            fuel="${this.getAttribute("fuel")}" 
            wet="${this.getAttribute("wet")}" 
            noise-level="${this.getAttribute("noise-level")}" 
            decibel="${this.getAttribute("decibel")}" 
            eprel-id="${this.getAttribute("eprel-id")}" 
            alt-image="//r2.eurowheels.dk/gfx/eu/tyres/${this.getAttribute("fuel")}${this.getAttribute("wet")}${this.getAttribute("noise-level")}${this.getAttribute("decibel")}.jpg"
            ${this.hasAttribute("ice-grip") ? "ice-grip" : ""}
            ${this.hasAttribute("snow-grip") ? "snow-grip" : ""}>
        </${EnergyLabelElement.tag}>
    `;

    private eprelView = (eprelId: string) => {
        if (!eprelId || eprelId == "" || eprelId == "0") {
            return "";
        }

        return `<a href="https://eprel.ec.europa.eu/screen/product/tyres/${eprelId}" target="_blank" style="white-space:nowrap">EPREL ${eprelId}</a>`;

    };  

}

customElements.define(TyreDisplayElement.tag, TyreDisplayElement);