import { ProductList, ProductsStore, SearchRequest } from "./ProductsStore";
import "./AccessoriesPickerElement.less";
import { WaitSpinnerElement } from "../../features/Spinner";
import { TpmsBuyDialogElement } from "../../features/BuyButton/TpmsBuyDialogElement";

export class AccessoriesPickerElement extends HTMLElement {
    static tag = "accessories-picker";
    private _select: HTMLSelectElement;
    private _text: HTMLInputElement;
    private _search: HTMLButtonElement;
    private _buyTPMS: HTMLButtonElement;

    connectedCallback() {
        const productList = ProductsStore.instance.getProducts();
        this.innerHTML = this.view(productList.accessoryTypes);
        this._select = this.querySelector("select");
        // this._select.addEventListener("change", this.refresh);

        this._text = this.querySelector("input");

        this._search = this.querySelector("button[name=search]");    

        this._search.addEventListener("click", this.refresh);

        ProductsStore.instance.addEventListener("products-updated", this.onProductsUpdated);
        this._buyTPMS = this.querySelector("button[name=buy-tpms]");

        this._buyTPMS.addEventListener("click", this.onBuyTPMS);

        this.update(productList.request);

    }

    disconnectedCallback() {
        ProductsStore.instance.removeEventListener("products-updated", this.onProductsUpdated);
        this._search.removeEventListener("click", this.refresh);
        this._buyTPMS.removeEventListener("click", this.onBuyTPMS);

    }

    private onBuyTPMS = async () => {   
        const licensePlate = this.querySelector("input[name=license-plate]") as HTMLInputElement;
        const dialog = new TpmsBuyDialogElement();
        dialog.setAttribute("license-plate", licensePlate.value);
        document.body.appendChild(dialog); 
    }   

    private onProductsUpdated = (event: CustomEvent<ProductList>) => {

        const request = event.detail.request;

        if (!request)
            return;

       this.update(request);
    }

    private update = (request: SearchRequest) => {
        this._select.value = request.accessoryType?.toString() ?? "";
        this._text.value = request.text ?? "";
    }

    private refresh = async () => {
        using spinner = WaitSpinnerElement.show(); 

        let id : number | undefined = undefined;

        if (this._select.value)
            id = parseInt(this._select.value);

        const text = this._text.value;
        await ProductsStore.instance.applyFilter(id, text);
    }

    view = (accessoryTypes: { [key: number]: string }) => `
        <header>Tilbehør</header>
        <div>
            <div class=filter>
                <label>
                    Varetype
                    <select>
                        <option value="">-- Vælg varetype -- </option>
                        <option value="0">Alle</option>
                        ${Object.keys(accessoryTypes).map(key => `
                            <option value="${key}">${accessoryTypes[key]}</option>
                        `).join("")}
                    </select>
                </label>
                <label>
                    Fritekst søgning
                    <input type=text placeholder="Søg efter vare" name=text />
                </label>
                <button type=button name=search>Søg</button>
            </div>
            <div class=divider></div>
            <div class=tpms>
                <label>
                    Bestil TPMS
                    <input type=text name=license-plate>
                </label>
                <button type=button name=buy-tpms>Køb 4 stk. TPMS</button>
            </div>
        </div>
    `;
}
customElements.define(AccessoriesPickerElement.tag, AccessoriesPickerElement);