import "./SiteFooterElement-style.less";

class SiteFooterElement extends HTMLElement {
    connectedCallback() {
        this.addEventListener("click", this.clickHandler);
    }

    private clickHandler = async (event: Event) => {
        const element = event.target as HTMLElement;
        const langaugeSelect = element.closest("[language-select]");
        
        if (!langaugeSelect) 
            return;

        const search = new URLSearchParams();
        search.append("language", langaugeSelect.getAttribute("language-select"));
            
        const result = await fetch(`/adminapi/api/setLanguage?${search.toString()}`)
        if (result.ok)
            window.location.reload();
    }

}


customElements.define("site-footer", SiteFooterElement);